import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import grayBackgroundImage from '../assets/images/grayBack.jpg';
import blackBackgroundImage from '../assets/images/back.jpg';
import { useAppDispatch } from '../redux/hook';
import { setTheme } from '../redux/features/themeSlice';

export function useBackgroundImage() {
  const location = useLocation();
  const dispatch = useAppDispatch();

  useEffect(() => {
    const isDarkTheme = location.pathname === '/'
      || location.pathname.includes('timeline')
      || location.pathname.includes('civilians');

    document.body.style.backgroundImage
      = isDarkTheme
        ? `url(${blackBackgroundImage})`
        : `url(${grayBackgroundImage})`;

    dispatch(setTheme(isDarkTheme ? 'dark' : 'light'));
  }, [location.pathname]);
}
