import { VictimStatus } from 'shared';

type VictimStatusButtonProps = {
  text: string;
  outlinedClass: string;
  filledClass: string;
  backgroundClass: string;
};

export function getVictimStatusProps(
  status: VictimStatus,
  isImperativeMoodText = false,
): VictimStatusButtonProps {
  switch (status) {
    case 'approved':
      return {
        text: isImperativeMoodText ? 'Підтвердити' : 'Підтверджено',
        outlinedClass:
        'btn-outline-success',
        filledClass: 'btn-success',
        backgroundClass: 'bg-success',
      };
    case 'rejected':
      return {
        text: isImperativeMoodText ? 'Відхилити' : 'Відхилено',
        outlinedClass: 'btn-outline-danger',
        filledClass: 'btn-danger',
        backgroundClass: 'bg-danger',
      };
    case 'waiting':
      return {
        text: isImperativeMoodText ? 'Очікує обробки' : 'Очікує',
        outlinedClass: 'btn-outline-warning',
        filledClass: 'btn-warning',
        backgroundClass: 'bg-warning',
      };
    default:
      return {
        text: 'Невизначено',
        outlinedClass: '',
        filledClass: '',
        backgroundClass: 'bg-black',
      };
  }
}
