import React, { HTMLAttributes } from 'react';
import cn from 'classnames';

type Props = HTMLAttributes<HTMLElement>;

export const LoadingOverlay: React.FC<Props> = ({ className, ...props }) => {
  return (
    <div
      className={cn(
        'position-absolute top-0 start-0 end-0 bottom-0',
        'd-flex align-items-center justify-content-center gap-1',
        'rounded bg-cool opacity-75',
        className,
      )}
      {...props}
    >
      <div className="spinner-grow spinner-grow-sm" role="status" />
      <div className="spinner-grow spinner-grow-sm" role="status" />
      <div className="spinner-grow spinner-grow-sm" role="status" />
    </div>
  );
};
