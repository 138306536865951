import * as Yup from 'yup';
import { loadPlaquePolyglot } from '../utils/loadPolyglot.js';
import { emailSchema, phoneDtoSchema, phoneFormSchema, } from '../../index.js';
const victimSchema = (polyglot) => (Yup
    .object()
    .required(polyglot.t('victim.required'))
    .shape({
    value: Yup.number().nonNullable(),
    label: Yup.string().nonNullable(),
}));
const victimIdSchema = (polyglot) => (Yup
    .number()
    .required(polyglot.t('victimId.required')));
const isHeroSchema = Yup
    .boolean()
    .default(false);
const imageSchema = (polyglot) => (Yup
    .string()
    .required(polyglot.t('photo.required')));
const callSignSchema = (polyglot) => (Yup
    .string()
    .max(50, polyglot.t('callSign.max', { maxLength: 50 })));
const unitSchema = (polyglot) => (Yup
    .string()
    .max(250, polyglot.t('unit.max', { maxLength: 250 })));
const addressSchema = (polyglot) => (Yup
    .string()
    .required(polyglot.t('address.required'))
    .max(250, polyglot.t('address.max', { maxLength: 250 })));
export const createPlaqueFormSchema = (locale) => {
    const polyglot = loadPlaquePolyglot(locale);
    return Yup
        .object()
        .shape({
        victim: victimSchema(polyglot),
        address: addressSchema(polyglot),
        image: imageSchema(polyglot),
        isHero: isHeroSchema,
        callSign: callSignSchema(polyglot),
        unit: unitSchema(polyglot),
        email: emailSchema(polyglot),
    })
        .concat(phoneFormSchema(polyglot));
};
export const createPlaqueDtoSchema = (locale) => {
    const polyglot = loadPlaquePolyglot(locale);
    return Yup
        .object()
        .shape({
        victimId: victimIdSchema(polyglot),
        address: addressSchema(polyglot),
        image: imageSchema(polyglot),
        isHero: isHeroSchema,
        callSign: callSignSchema(polyglot),
        unit: unitSchema(polyglot),
        email: emailSchema(polyglot),
        phone: phoneDtoSchema(polyglot),
    })
        .noUnknown((params) => `Наступні передані параметри зайві: ${params.unknown}`)
        .strict();
};
export const createPlaqueOptionalDtoSchema = (locale) => {
    const polyglot = loadPlaquePolyglot(locale);
    return Yup
        .object()
        .shape({
        victimId: victimIdSchema(polyglot).optional(),
        address: addressSchema(polyglot).optional(),
        image: imageSchema(polyglot).optional(),
        isHero: isHeroSchema.optional(),
        callSign: callSignSchema(polyglot).optional(),
        unit: unitSchema(polyglot).optional(),
        email: emailSchema(polyglot).optional(),
        phone: phoneDtoSchema(polyglot).optional(),
    })
        .noUnknown((params) => `Наступні передані параметри зайві: ${params.unknown}`)
        .strict();
};
