/* eslint-disable max-len */
import React from 'react';
import cn from 'classnames';
import { VictimStatus } from 'shared';
import { getVictimStatusProps } from '../../helpers/getVictimStatusProps';

type Props = {
  status: VictimStatus;
  className?: string;
};

export const VictimStatusBadge: React.FC<Props> = React.memo(({ status, className }) => {
  const { text, backgroundClass } = getVictimStatusProps(status);

  return (
    <span className={cn(
      'badge rounded-pill d-inline fw-normal fs-6',
      backgroundClass,
      className,
    )}
    >
      {text}
    </span>
  );
});
