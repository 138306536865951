/* eslint-disable max-len */
import React, { useEffect, useState } from 'react';
import { v4 as uuid } from 'uuid';
import { useQuery } from 'react-query';
import InfiniteScroll from 'react-infinite-scroll-component';
import { IVictimBase } from 'shared';

import { PublicVictimItem, PublicVictimSkeleton } from '../PublicVictimItem';
import { publicService } from '../../services/publicService';
import { usePage } from '../../hooks/usePage';
import { VICTIMS_LOAD_PER_PAGE } from '../../constants/main';
import { useResolvedLanguage } from '../../hooks/useResolvedLanguage';

type Props = {
  query: string;
  isMilitary: boolean;
};

export const VictimsList: React.FC<Props> = React.memo(({ query, isMilitary }) => {
  const [victims, setVictims] = useState<IVictimBase[]>([]);
  const [victimsTotalAmount, setVictimsTotalAmount] = useState(0);
  const { page, increasePage, reset: resetPages } = usePage(0);
  const locale = useResolvedLanguage();

  useEffect(() => {
    resetPages();
  }, [query]);

  const { isLoading, isError } = useQuery({
    queryKey: ['victims', isMilitary, page, query, locale],
    queryFn: async () => {
      const { length, data } = await publicService.getVictims({
        isMilitary,
        query,
        offset: page * VICTIMS_LOAD_PER_PAGE,
        limit: VICTIMS_LOAD_PER_PAGE,
        locale,
      });

      if (page === 0) {
        setVictimsTotalAmount(length);
        setVictims(data);

        return;
      }

      setVictims(prevState => [...prevState, ...data]);
    },
  });

  const hasMore = victimsTotalAmount > victims.length;

  return (
    <div className="row">
      {(isLoading && page === 0) && (
        Array.from(
          { length: VICTIMS_LOAD_PER_PAGE },
          () => <PublicVictimSkeleton key={uuid()} />,
        )
      )}

      <InfiniteScroll
        dataLength={victims.length}
        next={increasePage}
        hasMore={hasMore}
        loader={Array.from(
          { length: VICTIMS_LOAD_PER_PAGE },
          () => <PublicVictimSkeleton key={uuid()} />,
        )}
        scrollThreshold={0.8}
        className="row"
      >
        {victims.map((victim) => (
          <PublicVictimItem
            key={victim.id}
            victim={victim}
          />
        ))}
      </InfiniteScroll>

      {isError && (
        <div className="col">
          <div className="alert alert-danger w-100" role="alert">
            <span className="fs-5">
              Помилка під час завантаження людей
              <i className="bi bi-exclamation-circle ps-3 align-middle" />
            </span>
          </div>
        </div>
      )}
    </div>
  );
});
