import React from 'react';
import { useLocation, Navigate } from 'react-router-dom';
import { useAppSelector } from '../../redux/hook';
import { PulseLoader } from '../backdrop/PulseLoader';

type Props = {
  children: React.ReactNode;
};

export const RequireAuth: React.FC<Props> = ({ children }) => {
  const { isChecked, user } = useAppSelector(state => state.auth);
  const location = useLocation();

  // по идее сюда никогда не попадем, но пусть будет
  if (!isChecked) {
    <PulseLoader />;
  }

  if (!user) {
    return <Navigate to="/login" state={{ from: location }} replace />;
  }

  return children;
};
