import React from 'react';
import { Modify } from 'shared';
import placeholderImage from '../../assets/images/landscape-placeholder.svg';

type Props = Modify<React.ImgHTMLAttributes<HTMLImageElement>, {
  src: string | null | undefined;
}>;

export const Picture: React.FC<Props> = ({ src, ...props }) => {
  return (
    // eslint-disable-next-line jsx-a11y/alt-text
    <img
      src={src ? `${process.env.REACT_APP_FILES_URL}/${src}` : placeholderImage}
      {...props}
    />
  );
};
