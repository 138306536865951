import React from 'react';
import { useQuery } from 'react-query';
import { motion, useScroll, useSpring } from 'framer-motion';
import { useSearchParams } from 'react-router-dom';
import { v4 as uuid } from 'uuid';

import './TimelinePage.css';

import { IArticle } from '../../types/article';
import { publicService } from '../../services/publicService';
import {
  TimelineCard,
  TimelineCardSkeleton, TimelineLastCard,
} from '../../components/TimelineCard';
import { searchParamsNames } from '../../constants/search';
import { filterArticles } from '../../helpers/filterArticles';
import { PublicNav } from '../../components/PublicNav';
import { useResolvedLanguage } from '../../hooks/useResolvedLanguage';

export const TimelinePage: React.FC = () => {
  const { scrollYProgress } = useScroll();
  const scaleX = useSpring(scrollYProgress, {
    stiffness: 200,
    damping: 50,
  });
  const [searchParams] = useSearchParams();
  const locale = useResolvedLanguage();

  const date = new Date();
  const dateFormat = new Intl.DateTimeFormat('ko-KR',
    { year: 'numeric', month: '2-digit', day: '2-digit' }).format(date);
  const formattedDateFormat = dateFormat.replace(/\. /g, '-').slice(0, -1);

  const lastTimelineCard = {
    id: 0,
    title: locale === 'ua'
      ? 'Наразі спокійно. Бережіть себе та близьких. Рухаємося до перемоги! '
      : 'Currently calm. Take care of yourself and your loved ones.'
       + 'Moving towards victory!',
    date: formattedDateFormat,
  };

  const {
    data: articles,
    isLoading,
    isError,
    isSuccess,
  } = useQuery<IArticle[]>({
    queryKey: ['articles'],
    queryFn: publicService.getArticles,
    onSuccess: () => scrollYProgress.set(0, false),
  });

  const filteredArticles = filterArticles(
    articles ?? [],
    searchParams.get(searchParamsNames.query),
    locale,
  );

  return (
    <div className="container">
      <motion.div
        hidden={!isSuccess}
        className="timeline-page-progress-bar"
        style={{ scaleX }}
      />

      <PublicNav />

      <div className="row pt-5">
        {isError && (
          <div className="col">
            <div className="alert alert-danger w-100" role="alert">
              <span className="fs-5">
                Помилка під час завантаження людей
                <i className="bi bi-exclamation-circle ps-3 align-middle" />
              </span>
            </div>
          </div>
        )}

        {isLoading && (Array.from({ length: 10 }, () => (
          <TimelineCardSkeleton key={uuid()} />
        )))}

        {isSuccess && (
          <TimelineLastCard
            key={uuid()}
            article={lastTimelineCard}
            locale={locale}
          />
        )}

        {isSuccess && (
          filteredArticles.map((article) => (
            <TimelineCard
              key={article.id}
              article={article}
            />
          ))
        )}

      </div>
    </div>
  );
};
