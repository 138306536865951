import React from 'react';
import { Locale } from 'shared';
import { useTranslation } from 'react-i18next';

import { LoadingButton } from '../LoadingButton';

type Props = {
  isForUpdating: boolean;
  locale: Locale;
  resetForm: () => void;
  isCreating: boolean;
  isUpdating: boolean;
};

export const FormActionButtonGroup: React.FC<Props> = ({
  isForUpdating,
  locale,
  resetForm,
  isCreating,
  isUpdating,
}) => {
  const { t } = useTranslation('main');

  return (
    <div
      className="row pt-4 pt-lg-0 gx-3
      justify-content-center justify-content-md-end"
    >
      <div className="col-6 col-sm-4 col-lg-2">
        <button
          type="button"
          className="btn btn-lg btn-outline-primary
          w-100 bg-light text-secondary"
          onClick={() => resetForm()}
        >
          {t('clear', { lng: locale })}
        </button>
      </div>

      <div className="col-6 col-sm-4 col-lg-2 fs-4">
        {isForUpdating ? (
          <LoadingButton
            type="submit"
            isLoading={isUpdating}
            disabled={isUpdating}
            className="fs-4"
          >
            {t('save', { lng: locale })}
          </LoadingButton>
        ) : (
          <LoadingButton
            type="submit"
            isLoading={isCreating}
            disabled={isCreating}
            className="fs-4"
          >
            {t('submit', { lng: locale })}
          </LoadingButton>
        )}
      </div>
    </div>
  );
};
