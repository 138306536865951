import React from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import { Logo } from '../Logo';
import { Footer } from '../Footer';

export const PublicMainLayout: React.FC = () => {
  const location = useLocation();

  return (
    <div className="d-flex flex-column min-vh-100">
      <header className="container position-relative">
        <div className="p-5 d-flex justify-content-center">
          <Logo />
        </div>
      </header>

      <main className="flex-grow-1">
        <Outlet />
      </main>

      {location.pathname !== '/application' && (
        <footer className="mt-auto">
          <Footer />
        </footer>
      )}
    </div>
  );
};
