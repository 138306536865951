import { configureStore } from '@reduxjs/toolkit';
import victimsSlice from './features/victimsSlice';
import authSlice from './features/authSlice';
import articlesSlice from './features/articlesSlice';
import themeSlice from './features/themeSlice';
import backdropSlice from './features/backdropSlice';
import plaquesSlice from './features/plaquesSlice';

export const store = configureStore({
  reducer: {
    auth: authSlice,
    victims: victimsSlice,
    articles: articlesSlice,
    theme: themeSlice,
    backdrop: backdropSlice,
    plaques: plaquesSlice,
  },
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
