import * as Yup from 'yup';
import { victimStatusSchema } from './victimStatusSchema.js';
import { slugSchema } from './slugSchema.js';
export const changeVictimStatusSchema = Yup
    .object()
    .shape({
    status: victimStatusSchema,
    slug: slugSchema
        .test('requiredIfApproved', 'Slug is required when status is approved', (value, context) => {
        if (context.parent.status !== 'approved' && value) {
            throw context.createError({
                path: context.path,
                message: 'Слаг не потрібен, якщо заявка не підтверджується',
            });
        }
        return true;
    }),
});
