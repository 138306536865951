/* eslint-disable no-param-reassign */
import { wait } from '../helpers/wait';
import { accessTokenService } from '../services/accessTokenService';
import { createClient } from './createClient';

export const adminClient = createClient();

adminClient.interceptors.response.use(res => res.data);
adminClient.interceptors.request.use(async (req) => {
  const accessToken = accessTokenService.get();

  if (accessToken) {
    req.headers.Authorization = `Bearer ${accessToken}`;
  }

  req.headers['Cache-Control'] = 'no-cache';

  // for development
  await wait();

  return req;
});
