import React from 'react';
import { useQuery } from 'react-query';

import { ArticlesList } from '../../components/ArticlesList';
import { ArticlesForm } from '../../components/ArticleForm';

import { useAppDispatch, useAppSelector } from '../../redux/hook';
import { setArticles } from '../../redux/features/articlesSlice';
import { publicService } from '../../services/publicService';
import { IArticle } from '../../types/article';

import { AdminPanelLayout } from '../../components/AdminPanelLayout';

export const ArticlesAdminPage: React.FC = () => {
  const dispath = useAppDispatch();
  const {
    selectedArticleId,
    articles,
  } = useAppSelector(state => state.articles);
  const { isSuccess, isLoading, isError } = useQuery<IArticle[]>({
    queryKey: ['articles'],
    queryFn: publicService.getArticles,
    onSuccess: (articlesFromServer) => {
      dispath(setArticles(articlesFromServer));
    },
  });

  const selectedArticle = articles.find(article => (
    article.id === selectedArticleId
  ));

  return (
    <AdminPanelLayout
      leftPanel={(
        <>
          {isLoading && (
            <div className="spinner-border d-block m-auto" role="status">
              <span className="visually-hidden">Loading...</span>
            </div>
          )}

          {isError && (
            <div className="alert alert-danger d-block m-auto" role="alert">
              Помилка під час завантаження подій
            </div>
          )}

          {isSuccess && (
            <ArticlesList />
          )}
        </>
      )}
      rightPanel={<ArticlesForm currentArticle={selectedArticle} />}
    />
  );
};
