/* eslint-disable no-param-reassign */
import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { IArticle } from '../../types/article';

interface StateType {
  articles: IArticle[];
  selectedArticleId: number | null;
}

const initialState: StateType = {
  articles: [],
  selectedArticleId: null,
};

const articlesSlice = createSlice({
  name: 'articlesSlice',
  initialState,
  reducers: {
    setArticles: (state, action: PayloadAction<IArticle[]>) => {
      state.articles = action.payload;
    },
    selectArticle: (state, action: PayloadAction<number | null>) => {
      state.selectedArticleId = action.payload;
    },
    addArticle: (state, action: PayloadAction<IArticle>) => {
      state.articles = [action.payload, ...state.articles];
    },
    updateArticle: (state, action: PayloadAction<IArticle>) => {
      state.articles = state.articles.map(article => (
        article.id === action.payload.id
          ? action.payload
          : article
      ));
    },
    deleteArticle: (state, action: PayloadAction<number>) => {
      state.articles = state.articles.filter(article => (
        article.id !== action.payload
      ));

      if (state.selectedArticleId === action.payload) {
        state.selectedArticleId = null;
      }
    },
  },
});

export const {
  setArticles,
  selectArticle,
  addArticle,
  updateArticle,
  deleteArticle,
} = articlesSlice.actions;

export default articlesSlice.reducer;
