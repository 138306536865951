import React from 'react';
import { motion, AnimatePresence } from 'framer-motion';

import { useAppSelector } from '../../redux/hook';
import { ArticlesItem } from '../ArticleItem';

export const ArticlesList: React.FC = () => {
  const { articles } = useAppSelector(state => state.articles);

  return (
    <AnimatePresence initial={false}>
      {articles.map(article => (
        <motion.li
          key={article.id}
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          layoutId={article.id.toString()}
          transition={{ duration: 0.5 }}
        >
          <ArticlesItem article={article} />
        </motion.li>
      ))}
    </AnimatePresence>
  );
};
