import React from 'react';
import cn from 'classnames';
import { useField } from 'formik';

type Option = {
  value: {};
  text: string;
};

type Props = {
  name: string;
  firstOption: Option;
  secondOption: Option;
};

export const Toggler: React.FC<Props> = ({
  name,
  firstOption,
  secondOption,
}) => {
  const [, meta, helpers] = useField(name);

  const { value } = meta;
  const { setValue } = helpers;

  return (
    <div className="d-flex btn-group" role="group">
      <button
        type="button"
        className={cn(
          'btn btn-light',
          {
            'btn-outline-dark': value !== firstOption.value,
            'btn-dark': value === firstOption.value,
          },
        )}
        onClick={() => setValue(firstOption.value)}
      >
        {firstOption.text}
      </button>

      <button
        type="button"
        className={cn(
          'btn btn-light',
          {
            'btn-outline-dark': value !== secondOption.value,
            'btn-dark': value === secondOption.value,
          },
        )}
        onClick={() => setValue(secondOption.value)}
      >
        {secondOption.text}
      </button>
    </div>
  );
};
