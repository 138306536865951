import React from 'react';
import { Link } from 'react-router-dom';
import Select, { StylesConfig, SingleValue } from 'react-select';
import { Locale } from 'shared';
import { useField, useFormikContext } from 'formik';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';

import placeholderImage from '../../assets/images/landscape-placeholder.svg';
import { publicService } from '../../services/publicService';
import { getFullName } from '../../helpers/getFullName';
import { withErrorMessage } from '../withErrorMessage';
import { VictimOption } from './VictimSelect.types';
import { Picture } from '../Picture';
import { TPlaqueForm } from '../../types/plaque';

type Props = {
  name: string;
  locale: Locale;
  isDisabled?: boolean;
};

export const VictimSelect: React.FC<Props> = withErrorMessage(({
  name,
  locale,
  isDisabled,
}) => {
  const { t } = useTranslation('plaque', { keyPrefix: 'victimSelect' });
  const [, meta, helpers] = useField<VictimOption | null>(name);
  const { setFieldValue } = useFormikContext<TPlaqueForm>();
  const { data: victims, isLoading } = useQuery({
    queryKey: ['heroes-and-civilians', locale],
    queryFn: async () => {
      const [{ data: heroes }, { data: civilians }] = await Promise.all([
        publicService.getVictims({ isMilitary: true, locale }),
        publicService.getVictims({ isMilitary: false, locale }),
      ]);

      const victimsFromServer = [...heroes, ...civilians]
        .map(victim => ({
          value: victim.id,
          label: getFullName(victim),
          image: victim.image,
          isMilitary: victim.isMilitary,
        }));

      return [
        {
          value: 0,
          label: t('emptyValue'),
          image: placeholderImage,
          isMilitary: false,
        },
        ...victimsFromServer,
      ];
    },
  });

  const { value } = meta;
  const { setValue } = helpers;

  const customSelectStyles: StylesConfig<VictimOption> = {
    control: (provided) => ({
      ...provided,
      borderRadius: 'var(--bs-border-radius-pill) !important',
    }),
    valueContainer: (provided) => ({
      ...provided,
      padding: 4,
      borderRadius: 'var(--bs-border-radius-pill) !important',
      fontSize: 'calc(1.375rem)',
      backgroundColor: 'white',
    }),
    option: (provided) => ({
      ...provided,
      cursor: 'pointer',
      fontSize: 16,
    }),
    indicatorSeparator: (provided) => ({
      ...provided,
      display: 'none',
    }),
    menu: (provided) => ({
      ...provided,
      backgroundColor: 'white',
    }),
  };

  const handleSelectChange = (newValue: SingleValue<VictimOption>) => {
    setValue(newValue?.value === 0 ? null : newValue);

    if (!newValue?.isMilitary) {
      setFieldValue('isHero', false);
      setFieldValue('callSign', '');
      setFieldValue('unit', '');
    }
  };

  return (
    <Select
      isDisabled={isDisabled}
      isMulti={false}
      value={value}
      onChange={handleSelectChange}
      options={victims}
      maxMenuHeight={500}
      isLoading={isLoading}
      isSearchable
      loadingMessage={() => <span>{t('loading')}</span>}
      noOptionsMessage={() => (
        <Link to="/application" className="btn btn-link">
          {t('noSuchVictims')}
        </Link>
      )}
      aria-errormessage={t('errorMessage')}
      formatOptionLabel={victim => (
        <div className="d-flex ps-2 align-items-center">
          {victim.value === 0 ? (
            <img
              src={victim.image}
              alt={victim.label}
              className="table-image rounded-circle me-3"
            />
          ) : (
            <Picture
              src={victim.image}
              alt={victim.label}
              className="table-image rounded-circle me-3"
            />
          )}
          <span>{victim.label}</span>
        </div>
      )}
      placeholder={(
        <div className="d-flex justify-content-start ps-1 fs-4 text-secondary">
          {t('placeholder')}
        </div>
      )}
      filterOption={(option, inputValue) => (
        option.label.toLowerCase().includes(inputValue.trim().toLowerCase())
      )}
      styles={customSelectStyles}
    />
  );
});
