import React from 'react';
import { useAppSelector } from '../../redux/hook';
import { PlaqueItem } from '../PlaqueItem';

type Props = {
  selectedId: number | null;
};

export const PlaqueTable: React.FC<Props> = React.memo(({ selectedId }) => {
  const { plaques } = useAppSelector(state => state.plaques);

  return (
    <table className="table table-hover table-striped align-middle m-0">
      <thead>
        <tr>
          <th className="col-5">Ім&apos;я</th>
          <th className="col-5">Адреса</th>
          <th className="col-1">Тип</th>
          <th className="col-1">Додано</th>
        </tr>
      </thead>
      <tbody>
        {plaques.map(plaque => (
          <PlaqueItem
            key={plaque.id}
            plaque={plaque}
            isSelected={plaque.id === selectedId}
          />
        ))}
      </tbody>
    </table>
  );
});
