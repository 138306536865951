import React from 'react';
import { useField } from 'formik';
import PhoneInput from 'react-phone-number-input';

import { VictimForm } from '../../types/VictimForm';
import { withErrorMessage } from '../withErrorMessage';

import './ApplicationPhoneField.css';

type Props = {
  name: string;
  placeholder: string;
};

export const ApplicationPhoneField: React.FC<Props> = withErrorMessage(({
  name,
  placeholder,
}) => {
  const [, meta, helpers] = useField<VictimForm['phone']>(name);

  const { value } = meta;
  const { setValue } = helpers;

  return (
    <PhoneInput
      placeholder={placeholder}
      className="form-control rounded-pill fs-4 w-100"
      value={value}
      onChange={setValue}
      international
      defaultCountry="UA"
    />
  );
});
