import { useLayoutEffect } from 'react';
import { useAppDispatch } from '../../redux/hook';
import { hide, show } from '../../redux/features/backdropSlice';

export const Fallback: React.FC = () => {
  const dispatch = useAppDispatch();

  useLayoutEffect(() => {
    dispatch(show());

    return () => {
      dispatch(hide());
    };
  }, []);

  return <div className="visually-hidden" />;
};
