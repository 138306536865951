import React, { useMemo } from 'react';
import Select, { StylesConfig } from 'react-select';
import { useQuery } from 'react-query';
import { useField, useFormikContext } from 'formik';

import { adminService } from '../../services/adminService';
import { Picture } from '../Picture';
import { IconOption } from '../../types/IconOption';
import { ArticleForm } from '../../types/article';
import { withErrorMessage } from '../withErrorMessage';

type Props = {
  name: string;
};

export const IconPicker: React.FC<Props> = withErrorMessage(({ name }) => {
  const [, meta, helpers] = useField(name);
  const formik = useFormikContext<ArticleForm>();

  const { data: icons, isLoading } = useQuery({
    queryKey: ['icons'],
    queryFn: async () => {
      const iconsFromServer = await adminService.getIcons();

      return iconsFromServer.map(icon => ({
        value: icon.id,
        label: icon.src,
      }));
    },
  });

  const { value } = meta;
  const { setValue } = helpers;
  const { color } = formik.values;

  const customSelectStyles: StylesConfig<IconOption> = useMemo(() => ({
    control: (provided) => ({
      ...provided,
      width: 'fit-content',
    }),
    menu: (provided) => ({
      ...provided,
      width: 'fit-content',
    }),
    valueContainer: (provided) => ({
      ...provided,
      backgroundColor: value && color,
      padding: 10,
      borderRadius: 3,
    }),
    option: (provided) => ({
      ...provided,
      cursor: 'pointer',
    }),
    indicatorSeparator: (provided) => ({
      ...provided,
      display: 'none',
    }),
  }), [value, color]);

  return (
    <Select
      isMulti={false}
      value={value}
      onChange={(v) => setValue(v)}
      options={icons}
      maxMenuHeight={500}
      isLoading={isLoading}
      isSearchable={false}
      loadingMessage={() => <span>Іконки завантажуються</span>}
      noOptionsMessage={() => <span>Іконок немає</span>}
      aria-errormessage="Іконки не завантажились"
      formatOptionLabel={icon => (
        <div className="d-flex justify-content-center">
          <Picture src={icon.label} alt="Event icon" />
        </div>
      )}
      placeholder={(
        <div className="d-flex text-center justify-content-center">
          <div className="fs-4" style={{ height: 36, width: 36 }}>
            <i className="bi bi-question text-black align-middle" />
          </div>
        </div>
      )}
      styles={customSelectStyles}
    />
  );
});
