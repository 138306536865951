import React from 'react';
import cn from 'classnames';
import { useAppSelector } from '../../redux/hook';

import uaIcon from '../../assets/images/ua-48.png';
import enIcon from '../../assets/images/en-48.png';
import './SwitchLanguageButton.css';

type Props = {
  lang: string | undefined;
  onChange: (lang: string | undefined) => void;
};

export const SwitchLanguageButton: React.FC<Props> = ({ lang, onChange }) => {
  const { value: theme } = useAppSelector(state => state.theme);

  return (
    <div className="d-flex gap-1 align-items-center">
      <img
        src={lang === 'en' ? enIcon : uaIcon}
        className="flag-icon"
        alt={lang}
      />

      <ul className="d-flex m-0 fs-5">
        {[
          { locale: 'ua', label: 'Укр' },
          { locale: 'en', label: 'En' },
        ].map(({ locale, label }, i, array) => (
          <React.Fragment key={locale}>
            <li className="d-flex align-items-center position-relative">
              <button
                className={cn(
                  'lh-1 px-2 py-1',
                  {
                    'text-light': theme === 'dark' && lang === locale,
                    'switch-language-button-light': theme === 'dark'
                      && lang !== locale,
                    'switch-language-button-dark': theme === 'light'
                      && lang === locale,
                    'switch-language-button-dark--selected': theme === 'light'
                      && lang !== locale,
                  },
                )}
                type="button"
                onClick={() => onChange(locale)}
              >
                {label}
              </button>
            </li>

            {i !== array.length - 1 && (
              <span className={cn({ 'text-light': theme === 'dark' })}>|</span>
            )}
          </React.Fragment>
        ))}
      </ul>
    </div>
  );
};
