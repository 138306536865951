import React, {
  ComponentProps,
  useCallback,
  useEffect,
  useMemo,
  useRef,
} from 'react';
import {
  Formik,
  Form,
  FormikProps,
  FormikErrors,
} from 'formik';
import { IPlaque, createPlaqueFormSchema } from 'shared';
import { useTranslation } from 'react-i18next';
import { updatedDiff } from 'deep-object-diff';
import { useNavigate, Link } from 'react-router-dom';
import { useMutation } from 'react-query';

import { TPlaqueForm } from '../../types/plaque';
import { Toggler } from '../Toggler';
import { FormActionButtonGroup } from '../FormActionButtonGroup';
import { useResolvedLanguage } from '../../hooks/useResolvedLanguage';
import { VictimSelect } from '../VictimSelect';
import { TextField } from '../TextField';
import { ApplicationImageUploader } from '../ApplicationImageUploader';
import { AppError, isApiError } from '../../types/Error';
import { adminService } from '../../services/adminService';
import { toastService } from '../../services/toastService';
import { apiErrorService } from '../../services/apiErrorService';
import { getPlaqueFormValues } from '../../helpers/getPlaqueFormValues';
import { updatePlaque } from '../../redux/features/plaquesSlice';
import { useAppDispatch } from '../../redux/hook';
import { ContactsFieldGroup } from '../ContactsFieldGroup';
import { publicService } from '../../services/publicService';

type CreateVariables = {
  payload: TPlaqueForm,
  setErrors: (errors: FormikErrors<TPlaqueForm>) => void;
};

type UpdateVariables = {
  id: number;
  payload: Partial<TPlaqueForm>;
  setErrors: (errors: FormikErrors<TPlaqueForm>) => void;
};

const initialPlaqueValues: TPlaqueForm = {
  isHero: false,
  victim: null,
  image: null,
  callSign: '',
  unit: '',
  address: '',
  email: '',
  phone: '',
};

type Props = {
  currentPlaque?: IPlaque;
};

export const PlaqueForm: React.FC<Props> = ({ currentPlaque }) => {
  const locale = useResolvedLanguage();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const { t: tLabels } = useTranslation('plaque', {
    keyPrefix: 'labels',
  });
  const { t: tPlaceholders } = useTranslation('plaque', {
    keyPrefix: 'placeholders',
  });
  const { t: tCb } = useTranslation('plaque', {
    keyPrefix: 'checkBoxLabels',
  });
  const { t: tMessages } = useTranslation('plaque', {
    keyPrefix: 'messages',
  });

  const formikRef = useRef<FormikProps<TPlaqueForm>>(null);

  const { mutate: create, isLoading: isCreating }
    = useMutation<void, AppError, CreateVariables>({
      mutationFn: async ({ payload }) => {
        await publicService.createPlaque(payload, locale);
      },
      onError: (err, { setErrors }) => {
        const message = apiErrorService.getMessage(
          err, tMessages('createError'), locale,
        );

        toastService.error(message, {
          toastId: 'create-plaque-error',
        });

        if (isApiError(err) && err.response) {
          setErrors(err.response.data.errors);
        }
      },
      onSuccess: () => {
        toastService.success({
          title: tMessages('createSuccess'),
          description: tMessages('more'),
        });
        navigate('/');
      },
      onMutate: () => {
        toastService.dismiss();
      },
    });

  const { mutate: update, isLoading: isUpdating }
    = useMutation<IPlaque, AppError, UpdateVariables>({
      mutationFn: async ({ id, payload }) => {
        return adminService.updatePlaque(id, payload, locale);
      },
      onSuccess: (article) => {
        dispatch(updatePlaque(article));
        toastService.plaquesToast.success({
          title: tMessages('updateSuccess'),
        });
      },
      onError: (error, { setErrors }) => {
        const message = apiErrorService.getMessage(
          error, tMessages('updateError'), locale,
        );

        if (isApiError(error) && error.response) {
          setErrors(error.response.data.errors);
        }

        toastService.plaquesToast.error(message);
      },
      onMutate: () => toastService.plaquesToast.dismiss(),
    });

  const plaqueFormSchema = useMemo(() => {
    return createPlaqueFormSchema(locale);
  }, [locale]);

  useEffect(() => {
    if (formikRef.current) {
      formikRef.current.validateForm();
    }
  }, [locale]);

  const initialValues: TPlaqueForm = useMemo(() => (
    currentPlaque
      ? getPlaqueFormValues(currentPlaque)
      : initialPlaqueValues
  ), [currentPlaque, locale]);

  const onSubmit: ComponentProps<typeof Formik<TPlaqueForm>>['onSubmit']
    = useCallback((values, { setErrors }) => {
      if (currentPlaque) {
        const diff
          = updatedDiff(initialValues, values) as Partial<TPlaqueForm>;

        if (Object.keys(diff).length < 1) {
          toastService.articlesToast.warning(
            { title: 'Жодне з полів не було змінено' },
          );

          return;
        }

        update({ id: currentPlaque.id, payload: diff, setErrors });
      } else {
        create({ payload: values, setErrors });
      }
    }, [currentPlaque]);

  return (
    <div className="container mb-4 mb-xl-3">
      <Formik
        innerRef={formikRef}
        enableReinitialize
        initialValues={initialValues}
        validationSchema={plaqueFormSchema}
        onSubmit={onSubmit}
      >
        {({ resetForm, values }) => (
          <Form>
            <section className="mb-2">
              <h3 className="ms-2 lh-1">
                {tLabels('victim')}
                <br />
                <span className="text-muted fs-6">
                  {` (${tLabels('victimWarning')})`}
                </span>
              </h3>

              <div className="row">
                <div className="col-12 col-lg-8">
                  <VictimSelect
                    name="victim"
                    locale={locale}
                  />
                </div>

                {currentPlaque && (
                  <div className="col-12 col-lg-4">
                    <div className="d-flex align-items-center h-100">
                      <Link
                        className="fs-5 btn btn-link"
                        to={`/admin/victims/${currentPlaque.victim.id}`}
                      >
                        Перейти до людини
                      </Link>
                    </div>
                  </div>
                )}
              </div>
            </section>

            <section className="mb-3">
              <h3 className="ms-2 mb-3">
                {tLabels('address')}
              </h3>

              <div className="row">
                <div className="col-12 col-lg-8">
                  <TextField
                    name="address"
                    placeholder={tPlaceholders('address')}
                  />
                </div>
              </div>
            </section>

            <section className="mb-3">
              <div className="row">
                {values.victim?.isMilitary && (
                  <div className="col-12 col-lg-8">
                    <div className="mb-3">
                      <h3 className="ms-2">
                        {tLabels('isHero')}
                      </h3>

                      <Toggler
                        name="isHero"
                        firstOption={{
                          value: true,
                          text: tCb('isHero'),
                        }}
                        secondOption={{
                          value: false,
                          text: tCb('notHero'),
                        }}
                      />
                    </div>

                    <div className="mb-1">
                      <h3 className="ms-2">
                        {tLabels('callSign')}
                        <span className="text-muted fs-6">
                          {` (${tLabels('optional')})`}
                        </span>
                      </h3>

                      <TextField
                        name="callSign"
                        placeholder={tPlaceholders('callSign')}
                      />
                    </div>

                    <div className="mb-1">
                      <h3 className="ms-2">
                        {tLabels('unit')}
                        <span className="text-muted fs-6">
                          {` (${tLabels('optional')})`}
                        </span>
                      </h3>

                      <TextField
                        name="unit"
                        placeholder={tPlaceholders('unit')}
                      />
                    </div>
                  </div>
                )}

                <div className="col-12 col-lg-8">
                  <h3 className="ms-2">
                    {tLabels('photo')}
                  </h3>

                  <ApplicationImageUploader
                    name="image"
                    whiteStyle={!currentPlaque}
                    image={currentPlaque?.image}
                  />
                </div>
              </div>
            </section>

            <section className="col-12 col-lg-8 mb-lg-3">
              <ContactsFieldGroup locale={locale} />
            </section>

            <FormActionButtonGroup
              isForUpdating={Boolean(currentPlaque)}
              resetForm={resetForm}
              isCreating={isCreating}
              isUpdating={isUpdating}
              locale={locale}
            />
          </Form>
        )}
      </Formik>
    </div>
  );
};
