import Polyglot from 'node-polyglot';
import { VICTIM_VALIDATION_MSG } from '../messages/victim.js';
import { PLAQUE_VALIDATION_MSG } from '../messages/plaque.js';
function loadPolyglot(locale, validationObj) {
    const translations = locale === 'en'
        ? validationObj.en
        : validationObj.ua;
    const polyglot = new Polyglot({
        interpolation: { prefix: '{{', suffix: '}}' },
    });
    polyglot.extend(translations);
    return polyglot;
}
export const loadVictimPolyglot = (locale) => (loadPolyglot(locale, VICTIM_VALIDATION_MSG));
export const loadPlaquePolyglot = (locale) => (loadPolyglot(locale, PLAQUE_VALIDATION_MSG));
