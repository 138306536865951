import React, { ComponentProps, useCallback, useMemo } from 'react';
import { Formik, Form, FormikErrors } from 'formik';
import { useMutation } from 'react-query';
import { articleFormSchema } from 'shared';
import { updatedDiff } from 'deep-object-diff';

import { IArticle, ArticleForm } from '../../types/article';
import { LoadingButton } from '../LoadingButton/LoadingButton';
import { IconPicker } from '../IconPicker';
import { ColorPicker } from '../ColorPicker';
import { ApplicationDatePicker } from '../ApplicationDatePicker';
import { adminService } from '../../services/adminService';
import { useAppDispatch } from '../../redux/hook';
import {
  addArticle, selectArticle, updateArticle,
} from '../../redux/features/articlesSlice';
import { apiErrorService } from '../../services/apiErrorService';
import { AppError, isApiError } from '../../types/Error';
import { toastService } from '../../services/toastService';
import { ApplicationImageUploader } from '../ApplicationImageUploader';
import { getArticleFormValues } from '../../helpers/getArticleFormValues';
import { ArticleContent } from './ArticleContent';

const initialValuesForCreation: ArticleForm = {
  color: '#FFFFFF',
  icon: null,
  date: null,
  image: null,
  content: {
    ua: { title: '', text: '' },
    en: { title: '', text: '' },
  },
};

type CreateVariables = {
  payload: ArticleForm;
  setErrors: (errors: FormikErrors<ArticleForm>) => void;
  resetForm: () => void
};

type UpdateVariables = {
  id: number;
  payload: Partial<ArticleForm>;
  setErrors: (errors: FormikErrors<ArticleForm>) => void;
};

type Props = {
  currentArticle?: IArticle;
};

export const ArticlesForm: React.FC<Props> = ({ currentArticle }) => {
  const dispatch = useAppDispatch();

  const {
    isLoading: isCreating,
    mutate: create,
  } = useMutation<IArticle, AppError, CreateVariables>({
    mutationFn: async ({ payload }) => {
      return adminService.createArticle(payload);
    },
    onSuccess: (article, { resetForm }) => {
      dispatch(addArticle(article));
      resetForm();
      toastService.success({ title: 'Подію успішно створено' });
    },
    onError: (error, { setErrors }) => {
      const message = apiErrorService.getMessage(
        error, 'Не вдалося створити подію',
      );

      if (isApiError(error) && error.response) {
        setErrors(error.response.data.errors);
      }

      toastService.error(message);
    },
    onMutate: () => toastService.dismiss(),
  });

  const {
    isLoading: isUpdating,
    mutate: update,
  } = useMutation<IArticle, AppError, UpdateVariables>({
    mutationFn: async ({ id, payload }) => {
      return adminService.updateArticle(id, payload);
    },
    onSuccess: (article) => {
      dispatch(updateArticle(article));
      toastService.success({ title: 'Подію успішно змінено' });
    },
    onError: (error, { setErrors }) => {
      const message = apiErrorService.getMessage(
        error, 'Не вдалося редагувати подію',
      );

      if (isApiError(error) && error.response) {
        setErrors(error.response.data.errors);
      }

      toastService.error(message);
    },
    onMutate: () => toastService.dismiss(),
  });

  const initialValues: ArticleForm = useMemo(() => (
    currentArticle
      ? getArticleFormValues(currentArticle)
      : initialValuesForCreation
  ), [currentArticle]);

  const onSubmit: ComponentProps<typeof Formik<ArticleForm>>['onSubmit']
    = useCallback((values, { setErrors, resetForm }) => {
      if (currentArticle) {
        const diff
          = updatedDiff(initialValues, values) as Partial<ArticleForm>;

        if (Object.keys(diff).length < 1) {
          toastService.articlesToast.warning(
            { title: 'Жодне з полів не було змінено' },
          );

          return;
        }

        update({ id: currentArticle.id, payload: diff, setErrors });
      } else {
        create({ payload: values, setErrors, resetForm });
      }
    }, [currentArticle, create]);

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      validationSchema={articleFormSchema}
      onSubmit={onSubmit}
    >
      <Form className="px-4 pt-3">
        <div className="d-flex justify-content-between pe-3">
          <h2 className="display-6 mb-4 ">
            {currentArticle ? 'Редагування події' : 'Створення події'}
          </h2>

          {currentArticle && (
            <button
              type="button"
              className="btn btn-light d-inline-block"
              style={{ height: 40 }}
              onClick={() => dispatch(selectArticle(null))}
            >
              Створити подію
              <i className="bi bi-box-arrow-in-up-right ps-2" />
            </button>
          )}
        </div>

        <ArticleContent />

        <div className="d-flex mb-2">
          <div>
            <ColorPicker name="color" />
          </div>

          <div className="ms-4">
            <IconPicker name="icon" />
          </div>

          <div className="col-4 ms-4 mb-2">
            <ApplicationDatePicker name="date" />
          </div>
        </div>

        <div className="mb-2">
          <ApplicationImageUploader
            name="image"
            imagePreviewMaxHeight={500}
            image={currentArticle?.image}
          />
        </div>

        {currentArticle
          ? (
            <LoadingButton
              type="submit"
              isLoading={isUpdating}
              disabled={isUpdating}
            >
              Зберегти
            </LoadingButton>
          ) : (
            <LoadingButton
              isLoading={isCreating}
              disabled={isCreating}
              type="submit"
            >
              Створити
            </LoadingButton>
          )}
      </Form>
    </Formik>
  );
};
