import React from 'react';
import { Link, LinkProps, useLocation } from 'react-router-dom';
import cn from 'classnames';
import logo from '../../assets/images/logo.svg';

type Props = {
  isAdmin?: boolean;
} & Omit<LinkProps, 'to'>;

export const Logo: React.FC<Props> = ({
  className,
  isAdmin = false,
  ...props
}) => {
  const location = useLocation();

  return (
    <Link
      className={cn(
        className,
        'navbar-brand d-flex',
      )}
      to={isAdmin ? location.pathname : '/'}
      style={{ width: 'fit-content' }}
      {...props}
    >
      <img
        src={logo}
        alt="Logo"
        className="img-fluid"
      />
    </Link>
  );
};
