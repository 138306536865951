import React from 'react';
import cn from 'classnames';
import { IPlaque } from 'shared';
import { useNavigate } from 'react-router-dom';
import { getFullName } from '../../helpers/getFullName';
import { VictimStatusBadge } from '../VictimStatusBadge';
import { Picture } from '../Picture';

type Props = {
  plaque: IPlaque;
  isSelected: boolean;
};

export const PlaqueItem: React.FC<Props> = React.memo(({
  plaque,
  isSelected,
}) => {
  const navigate = useNavigate();
  const handleVictimSelect = () => {
    navigate(`/admin/plaques/${plaque.id}`);
  };

  return (
    <tr
      className={cn(
        'cursor-pointer',
        {
          'table-active': isSelected,
        },
      )}
      onClick={handleVictimSelect}
    >
      <td>
        <div className="d-flex align-items-center">
          <Picture
            src={plaque.victim.image}
            alt="Victim"
            className="table-image rounded-circle"
          />

          <p className="fw-bold m-0 ms-3">
            {getFullName(plaque.victim)}
          </p>
        </div>
      </td>

      <td>
        {plaque.address}
      </td>

      <td>
        <VictimStatusBadge status={plaque.status} />
      </td>

      <td>
        {new Date(plaque.createdAt).toLocaleDateString('ru-RU')}
      </td>
    </tr>
  );
});
