/* eslint-disable no-param-reassign */
import { wait } from '../helpers/wait';
import { createClient } from './createClient';

export const httpClient = createClient();

httpClient.interceptors.response.use(res => res.data);
httpClient.interceptors.request.use(async (req) => {
  // for development
  await wait();

  return req;
});
