import { IVictimFull, Locale } from 'shared';
import { VictimForm } from '../types/VictimForm';

export function getVictimFormValues(
  victim: IVictimFull,
  locale: Locale,
): VictimForm {
  const {
    name = '', surname = '', fatherName = '', biography = '', position = '',
  } = victim.content[locale] ?? {};

  return {
    name,
    surname,
    fatherName: fatherName ?? '',
    birthDate: victim.birthDate
      ? new Date(victim.birthDate)
      : null,
    deathDate: victim.deathDate
      ? new Date(victim.deathDate)
      : null,
    noDeathDate: !victim.deathDate,
    isMilitary: victim.isMilitary,
    position,
    biography,
    image: victim.image,
    email: victim.email ?? '',
    phone: victim.phone ?? undefined,
    hasMedal: victim.hasMedal,
    sex: victim.sex,
  };
}
