/* eslint-disable */
import React, { useState } from 'react';
import cn from 'classnames';
import { motion } from 'framer-motion';
import { LazyLoadImage } from 'react-lazy-load-image-component';

import './TimelineCard.css';
import arrowIcon from '../../assets/images/arrow-down-circle.svg';

import { IArticle } from '../../types/article';
import { Picture } from '../Picture';
import { getUkraineDate } from '../../helpers/getUkraineDate';
import { useResolvedLanguage } from '../../hooks/useResolvedLanguage';

type Props = {
  article: IArticle;
  isLast?: boolean;
};

export const TimelineCard: React.FC<Props> = ({
  article,
  isLast = false,
}) => {
  const [isOpened, setIsOpened] = useState(false);
  const locale = useResolvedLanguage();
  const { date, year } = getUkraineDate(article.date, locale);

  return (
    <div className="row text-light position-relative pb-6">
      <div className="col-md-3 col-lg-5 p-0">
        <div className="timeline-card-date-container d-flex flex-row
          flex-md-column text-center text-md-end opacity-75"
        >
          <span className="timeline-card-date">{date}</span>
          <span className="d-inline d-md-none">&nbsp;</span>
          <span className="timeline-card-year">{year}</span>
          <span className="d-inline d-md-none">&nbsp;</span>
          <span className="d-inline d-md-none">p.</span>
        </div>
      </div>

      <div className="col-2 col-md-2 col-lg-2 d-flex justify-content-center">
        <div
          style={{ backgroundColor: article.color }}
          className={cn(
            'rounded-circle d-flex justify-content-center align-items-center',
            'timeline-card-icon-container',
            {
              'timeline-card-line': !isLast,
            },
          )}
        >
          <Picture
            className="timeline-card-icon"
            src={article.icon.src}
            alt="Symbol"
          />
        </div>
      </div>

      <div className="col-10 col-md-7 col-lg-5 ps-0">
        <h6 className="fw-light mb-2 fs-5 lh-base">
          {article.content[locale].title || article.content['ua'].title}
        </h6>

        {article.image && (
          <LazyLoadImage
            className="p-0 timeline-card-image"
            effect="blur"
            threshold={200}
            src={`${process.env.REACT_APP_FILES_URL}/${article.image}`}
            alt="Event"
            useIntersectionObserver
          />
        )}

        <motion.button
          type="button"
          style={{ height: 40, width: 40 }}
          className="d-inline-block align-bottom ms-1 ms-md-3 lh-1 align-bottom"
          onClick={() => setIsOpened(prevState => !prevState)}
          whileHover={{ scale: 1.2 }}
          initial={{ rotate: 0 }}
          animate={{ rotate: isOpened ? 180 : 0 }}
          transition={{ type: 'just' }}
        >
          <img
            className="align-bottom"
            src={arrowIcon}
            alt="Toggle"
            width={30}
            height={30}
          />
        </motion.button>

        <div
          className={cn(
            'mt-4',
            'timeline-card-collapse',
            {
              opened: isOpened,
            },
          )}
        >
          <div
            dangerouslySetInnerHTML={{
              __html: article.content[locale].text || article.content['ua'].text,
            }}
          />
        </div>
      </div>
    </div>
  );
};
