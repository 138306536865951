import React, { useEffect } from 'react';
import { PlaqueForm } from '../../components/PlaqueForm';
import { toastService } from '../../services/toastService';

export const PlaquePage: React.FC = () => {
  useEffect(() => {
    return () => {
      toastService.dismiss('create-plaque-error');
    };
  }, []);

  return (
    <PlaqueForm />
  );
};
