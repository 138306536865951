import { IVictimBase } from 'shared';

export function getFullName(victim: IVictimBase): string {
  const { name, surname, fatherName } = victim;

  return [surname, name, fatherName]
    .map(part => (
      part
        ? part.slice(0, 1).toUpperCase() + part.slice(1).toLocaleLowerCase()
        : ''
    ))
    .join(' ');
}
