import React, { memo } from 'react';
import { useField } from 'formik';
import { VictimStatus, victimStatuses } from 'shared';
import { StatusButton } from '../../StatusButton';

type Props = {
  name: string;
  isLoading: boolean;
};

export const StatusSwitcher: React.FC<Props> = memo(({ name, isLoading }) => {
  const [, meta, helper] = useField<VictimStatus>(name);

  const { value, initialValue } = meta;
  const { setValue } = helper;

  return (
    <div className="btn-group" role="group">
      {victimStatuses.map(status => (
        <StatusButton
          key={status}
          status={status}
          isSelected={status === value || status === initialValue}
          isDisabled={isLoading}
          isLoading={status === value && isLoading}
          onClick={() => setValue(status)}
        />
      ))}
    </div>
  );
});
