import React from 'react';
import { IPlaque } from 'shared';
import { v4 as uuid } from 'uuid';
import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';

import { PlaqueTable } from '../../components/PlaqueTable';
import { PlaqueForm } from '../../components/PlaqueForm';

import { useAppDispatch, useAppSelector } from '../../redux/hook';

import { AdminPanelLayout } from '../../components/AdminPanelLayout';
import { adminService } from '../../services/adminService';
import { setPlaques } from '../../redux/features/plaquesSlice';
import { StatusChanger } from '../../components/StatusChanger';

export const PlaquesAdminPage: React.FC = () => {
  const dispatch = useAppDispatch();
  const { plaques } = useAppSelector(state => state.plaques);
  const { isSuccess, isLoading, isError } = useQuery<IPlaque[]>({
    queryKey: ['plaques'],
    queryFn: adminService.getPlaques,
    onSuccess: (plaquesFromServer) => {
      dispatch(setPlaques(plaquesFromServer));
    },
  });
  const { id: idParam } = useParams();
  const id = idParam ? parseInt(idParam, 10) : null;
  const selectedPlaque = plaques.find(plaque => plaque.id === id);

  return (
    <AdminPanelLayout
      leftPanel={(
        <>
          {isLoading && (
            <div className="spinner-border d-block m-auto" role="status">
              <span className="visually-hidden">Loading...</span>
            </div>
          )}

          {isError && (
            <div className="alert alert-danger d-block m-auto" role="alert">
              Помилка під час завантаження заявок на
              створення меморіальних дощок
            </div>
          )}

          {isSuccess && (
            <PlaqueTable selectedId={id} />
          )}
        </>
      )}
      rightPanel={
        selectedPlaque ? (
          <>
            <div className="container-fluid p-0 mb-5">
              <StatusChanger
                key={uuid()}
                id={selectedPlaque.id}
                initialStatus={selectedPlaque.status}
                changeFor="plaque"
              />
            </div>
            <PlaqueForm currentPlaque={selectedPlaque} />
          </>
        ) : (
          <p className="m-0 fs-4">
            Виберіть заявку зі списку&nbsp;
            <i className="bi bi-exclamation-triangle text-primary" />
          </p>
        )
      }
    />
  );
};
