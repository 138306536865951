/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';
import { check } from './authSlice';

interface StateType {
  isVisible: boolean;
}

const initialState: StateType = {
  isVisible: true,
};

const backdropSlice = createSlice({
  name: 'backdropSlice',
  initialState,
  reducers: {
    show: (state) => {
      state.isVisible = true;
    },
    hide: (state) => {
      state.isVisible = false;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(check, (state) => {
      state.isVisible = false;
    });
  },
});

export const { show, hide } = backdropSlice.actions;

export default backdropSlice.reducer;
