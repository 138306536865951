import React from 'react';
import cn from 'classnames';
import { Link, useNavigate, NavLink } from 'react-router-dom';
import { Logo } from '../Logo';
import { useAppDispatch } from '../../redux/hook';
import { logout } from '../../redux/features/authSlice';

type Props = {
  className?: string;
};

export const Header: React.FC<Props> = ({ className }) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  return (
    <header className={cn('w-100 border-bottom bg-light', className)}>
      <nav className="navbar py-2">
        <div className="container justify-content-between">
          <Logo isAdmin reloadDocument />

          <ul className="row m-0 p-0 gx-md-5">
            <li className="col">
              <Link className="fs-1" to="/" role="button" reloadDocument>
                <i className="bi bi-house-door text-primary align-middle" />
              </Link>
            </li>

            <li className="col p-0">
              <NavLink
                className={({ isActive }) => (
                  cn('fs-1 px-3', { 'bg-blue': isActive })
                )}
                to="/admin/victims"
                role="button"
              >
                <i className="bi bi-card-list text-primary align-middle" />
              </NavLink>
            </li>

            <li className="col p-0">
              <NavLink
                className={({ isActive }) => (
                  cn('fs-1 px-3', { 'bg-blue': isActive })
                )}
                to="/admin/articles"
                role="button"
              >
                <i className="bi bi-activity text-primary align-middle" />
              </NavLink>
            </li>

            <li className="col p-0">
              <NavLink
                className={({ isActive }) => (
                  cn('fs-1 px-3', { 'bg-blue': isActive })
                )}
                to="/admin/plaques"
                role="button"
              >
                <i className="bi bi-person-vcard text-primary align-middle" />
              </NavLink>
            </li>

            <li className="col">
              <button
                type="button"
                onClick={() => {
                  dispatch(logout());
                  navigate('/');
                }}
                className="fs-1"
              >
                <i className="bi bi-box-arrow-right text-primary" />
              </button>
            </li>
          </ul>
        </div>
      </nav>
    </header>
  );
};
