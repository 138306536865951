import React, { Suspense } from 'react';
import { Provider } from 'react-redux';
import { QueryClient, QueryClientProvider } from 'react-query';
import { BrowserRouter as Router } from 'react-router-dom';
import { SkeletonTheme } from 'react-loading-skeleton';
import { App } from './App';
import { store } from './redux/store';
import { ToastCustomContainer } from './components/ToastCustomContainer';
import { PulseLoader } from './components/backdrop/PulseLoader';
import { Fallback } from './components/backdrop/Fallback';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: false,
      cacheTime: 0,
    },
  },
});

export const Root: React.FC = () => {
  return (
    <Provider store={store}>
      <QueryClientProvider client={queryClient}>
        <Router>
          <SkeletonTheme baseColor="#313131" highlightColor="#444">
            <ToastCustomContainer />

            <PulseLoader />

            <Suspense fallback={<Fallback />}>
              <App />
            </Suspense>
          </SkeletonTheme>
        </Router>
      </QueryClientProvider>
    </Provider>
  );
};
