import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Locale } from 'shared';
import { toastService } from '../../services/toastService';
import { VictimForm } from '../../components/VictimForm';

export const ApplicationPage: React.FC = () => {
  const { i18n: { resolvedLanguage } } = useTranslation();

  useEffect(() => {
    return () => {
      toastService.dismiss('create-application-error');
    };
  }, []);

  return (
    <VictimForm locale={resolvedLanguage as Locale} />
  );
};
