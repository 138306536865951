/* eslint-disable no-param-reassign */
import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { IVictimFull } from 'shared';
import {
  ChangeVictimStatusPayload,
} from '../../types/api/victim';

interface StateType {
  victims: IVictimFull[];
}

type ChangeStatusPayload = PayloadAction<ChangeVictimStatusPayload>;

const initialState: StateType = {
  victims: [],
};

const victimsSlice = createSlice({
  name: 'victimSlice',
  initialState,
  reducers: {
    setVictims: (state, action: PayloadAction<IVictimFull[]>) => {
      state.victims = action.payload;
    },
    changeVictimStatus: (state, action: ChangeStatusPayload) => {
      state.victims = state.victims.map(victim => {
        if (victim.id !== action.payload.id) {
          return victim;
        }

        return {
          ...victim,
          status: action.payload.status,
        };
      });
    },
    updateVictim: (state, action: PayloadAction<IVictimFull>) => {
      state.victims = state.victims.map(victim => (
        victim.id === action.payload.id
          ? action.payload
          : victim
      ));
    },
  },
});

export const {
  setVictims,
  changeVictimStatus,
  updateVictim,
} = victimsSlice.actions;

export default victimsSlice.reducer;
