/* eslint-disable react-hooks/rules-of-hooks */
import { FC, useState } from 'react';
import { SwitchLanguageButton } from '../SwitchLanguageButton';

export const withLocale = <P extends { locale: string }>(
  WrappedComponent: FC<P>,
) => {
  const WithLocaleComponent: FC<Omit<P, 'locale'>> = (props) => {
    const [locale, setLocale] = useState<string | undefined>('ua');

    return (
      <>
        <div className="container mb-5 bg-light rounded p-1 ps-3">
          <SwitchLanguageButton
            lang={locale}
            onChange={(lang) => setLocale(lang)}
          />
        </div>

        <WrappedComponent {...props as P} locale={locale} />
      </>
    );
  };

  return WithLocaleComponent;
};
