/* eslint-disable */
import React, { useState } from 'react';
import cn from 'classnames';

import './TimelineCard.css';
import { getUkraineDate } from '../../helpers/getUkraineDate';
import { Locale } from 'shared';

type Props = {
  article: { title: string, date: string };
  locale: Locale;
  isLast?: boolean;
};

export const TimelineLastCard: React.FC<Props> = ({
  article,
  locale,
}) => {
  const { date, year } = getUkraineDate(article.date, locale);

  return (
    <div className="row text-light position-relative pb-6">
      <div className="col-md-3 col-lg-5 p-0">
        <div className="timeline-card-date-container d-flex flex-row
          flex-md-column text-center text-md-end opacity-75"
        >
          <span className="timeline-card-date">{date}</span>
          <span className="d-inline d-md-none">&nbsp;</span>
          <span className="timeline-card-year">{year}</span>
          <span className="d-inline d-md-none">&nbsp;</span>
          <span className="d-inline d-md-none">p.</span>
        </div>
      </div>

      <div className="col-2 col-md-2 col-lg-2 d-flex justify-content-center">
        <div
          style={{ backgroundColor: '#7f7f7f' }}
          className={cn(
            'rounded-circle d-flex justify-content-center align-items-center',
            'timeline-card-icon-container timeline-card-line',
          )}
        >
        </div>
      </div>

      <div className="col-10 col-md-7 col-lg-5 ps-0">
        <h6 className="fw-light mb-2 fs-5 lh-base">
          {article.title}
        </h6>
      </div>
    </div>
  );
};
