import { ArticleForm, IArticle } from '../types/article';

export function getArticleFormValues(article: IArticle): ArticleForm {
  return {
    content: article.content,
    color: article.color,
    icon: {
      value: article.icon.id,
      label: article.icon.src,
    },
    date: new Date(article.date),
    image: article.image, // set image to null here.
    // Pass image to FilePond - it loads image from server and set this prop inside itself
  };
}
