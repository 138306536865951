import { IVictim } from 'shared';
import { convertLocale } from './convertLocale';

export function getDateInterval(
  victim: IVictim,
  locale: string | undefined,
): string {
  const dateLocale = convertLocale(locale);

  const birthDate = victim.birthDate
    ? new Date(victim.birthDate).toLocaleDateString(dateLocale)
    : '';
  const deathDate = victim.deathDate
    ? new Date(victim.deathDate).toLocaleDateString(dateLocale)
    : 'невід.';

  return birthDate ? `${birthDate} - ${deathDate}` : deathDate;
}
