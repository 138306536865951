import React from 'react';
import './TimelineCard.css';
import Skeleton from 'react-loading-skeleton';

export const TimelineCardSkeleton: React.FC = () => {
  return (
    <div className="row text-light position-relative pb-6">
      <div className="col-md-3 col-lg-5 p-0">
        <div className="timeline-card-date-container d-flex flex-row
          flex-md-column text-center text-md-end opacity-75"
        >
          <span className="timeline-card-date">
            <Skeleton width={150} />
          </span>

          <span className="d-none d-md-block timeline-card-year mt-2">
            <Skeleton width={185} />
          </span>
        </div>
      </div>

      <div className="col-2 col-md-2 col-lg-2 d-flex justify-content-center">
        <div
          style={{ height: 40, width: 40 }}
          className="rounded-circle d-flex justify-content-center
            align-items-center"
        >
          <Skeleton height={40} width={40} circle />
        </div>
      </div>

      <div className="col-10 col-md-7 col-lg-5 ps-0">
        <h6 className="fw-light mb-2 fs-5 lh-base">
          <Skeleton />
          <Skeleton width="50%" />
        </h6>

        <div className="d-inline-block">
          <Skeleton height={200} width={350} />
        </div>

        <button
          type="button"
          style={{ height: 40, width: 40 }}
          className="d-inline-block align-bottom ms-1 ms-md-3 lh-1 align-bottom"
        >
          <Skeleton height={40} width={40} circle />
        </button>
      </div>
    </div>
  );
};
