import React from 'react';
import { VictimItem } from '../VictimItem';
import { useAppSelector } from '../../redux/hook';

type Props = {
  selectedId: number | null;
};

export const VictimsTable: React.FC<Props> = React.memo(({ selectedId }) => {
  const { victims } = useAppSelector(state => state.victims);

  return (
    <table className="table table-hover table-striped align-middle m-0">
      <thead>
        <tr>
          <th>Ім&apos;я</th>
          <th>Тип</th>
          <th>Статус</th>
          <th>Додано</th>
          <th>Пошта</th>
        </tr>
      </thead>
      <tbody>
        {victims.map(victim => (
          <VictimItem
            key={victim.id}
            victim={victim}
            isSelected={selectedId === victim.id}
          />
        ))}
      </tbody>
    </table>
  );
});
