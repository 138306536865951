/* eslint-disable @typescript-eslint/no-shadow */
import * as Yup from 'yup';
import { isPossiblePhoneNumber } from 'react-phone-number-input';
import { checkIfStringIsDate } from '../utils/checkIfStringIsDate.js';
import { sexes } from '../../index.js';
import { loadVictimPolyglot } from '../utils/loadPolyglot.js';
const nameRegex = {
    ua: /^[\p{Script=Cyrillic}\s'-]+$/u,
    en: /^[a-zA-Z\s'-]+$/,
};
const nameSchema = (namePart, polyglot, locale) => (Yup
    .string()
    .required(polyglot.t('name.required', { namePart }))
    .matches(nameRegex[locale], polyglot.t('name.symbols', { namePart }))
    .min(2, polyglot.t('name.min', { namePart }))
    .max(50, polyglot.t('name.max', { namePart })));
const positionSchema = (polyglot) => (Yup
    .string()
    .required(polyglot.t('position.required'))
    .max(250, polyglot.t('position.max')));
const isMilitarySchema = Yup
    .boolean()
    .default(true);
export const emailSchema = (polyglot) => (Yup
    .string()
    .required(polyglot.t('email.required'))
    .email(polyglot.t('email.wrongFormat')));
const biographySchema = (polyglot) => (Yup
    .string()
    .required(polyglot.t('biography.required'))
    .max(65000, polyglot.t('biography.max')));
const imageSchema = (polyglot) => (Yup
    .string()
    .required(polyglot.t('photo.required')));
const yearsOfLifeFormSchema = (polyglot) => (Yup
    .object()
    .shape({
    birthDate: Yup
        .date()
        .defined()
        .nullable(),
    deathDate: Yup
        .date()
        .nullable()
        .when(['noDeathDate', 'birthDate'], ([noDeathDate, birthDate], schema) => {
        return noDeathDate
            ? schema
            : schema
                .required(polyglot.t('date.required'))
                .when(['birthDate'], {
                is: null,
                then: (schema) => schema,
                otherwise: (schema) => schema
                    .min(birthDate, polyglot.t('date.deathAfterBirth')),
            });
    }),
    noDeathDate: Yup
        .boolean()
        .default(false),
}));
const dateDtoSchema = (polyglot) => (Yup
    .string()
    .nullable()
    .defined()
    .test('is-date', polyglot.t('date.wrongDateString'), checkIfStringIsDate));
export const phoneFormSchema = (polyglot) => (Yup
    .object()
    .shape({
    phone: Yup.string()
        .test('is-phone-number', () => polyglot.t('phone.wrongFormat'), (value) => (value === undefined
        || isPossiblePhoneNumber(value))),
}));
export const phoneDtoSchema = (polyglot) => (Yup
    .string()
    .required()
    .nullable()
    .test('is-phone-number', () => polyglot.t('phone.wrongFormat'), (value) => (value === null
    || value === undefined
    || isPossiblePhoneNumber(value))));
const hasMedalSchema = Yup
    .boolean()
    .default(false);
const sexSchema = (polyglot) => (Yup
    .string()
    .required(polyglot.t('sex.required'))
    .oneOf(Object.values(sexes), polyglot.t('sex.oneOf', { oneOf: Object.values(sexes) })));
const createVictimBaseSchema = (polyglot, locale) => {
    const name = nameSchema(polyglot.t('namePart.firstName'), polyglot, locale);
    const surname = nameSchema(polyglot.t('namePart.surname'), polyglot, locale);
    const fatherName = nameSchema(polyglot.t('namePart.fatherName'), polyglot, locale)
        .nullable()
        .optional();
    return Yup
        .object()
        .shape({
        name,
        surname,
        fatherName,
        position: positionSchema(polyglot),
        isMilitary: isMilitarySchema,
        email: emailSchema(polyglot),
        biography: biographySchema(polyglot),
        image: imageSchema(polyglot),
    });
};
export const createVictimFormSchema = (locale) => {
    const polyglot = loadVictimPolyglot(locale);
    const victimBaseSchema = createVictimBaseSchema(polyglot, locale);
    return Yup
        .object()
        .shape({
        hasMedal: hasMedalSchema,
        sex: sexSchema(polyglot),
    })
        .concat(victimBaseSchema)
        .concat(yearsOfLifeFormSchema(polyglot))
        .concat(phoneFormSchema(polyglot));
};
export const createVictimApplicationDtoSchema = (locale) => {
    const polyglot = loadVictimPolyglot(locale);
    const victimBaseSchema = createVictimBaseSchema(polyglot, locale);
    return Yup
        .object()
        .shape({
        birthDate: dateDtoSchema(polyglot),
        deathDate: dateDtoSchema(polyglot),
        phone: phoneDtoSchema(polyglot),
    })
        .concat(victimBaseSchema)
        .noUnknown((params) => `Наступні передані параметри зайві: ${params.unknown}`);
};
export const createVictimOptionalDtoSchema = (locale) => {
    const polyglot = loadVictimPolyglot(locale);
    const name = nameSchema(polyglot.t('namePart.firstName'), polyglot, locale);
    const surname = nameSchema(polyglot.t('namePart.surname'), polyglot, locale);
    const fatherName = nameSchema(polyglot.t('namePart.fatherName'), polyglot, locale);
    return Yup
        .object()
        .shape({
        name: name.optional(),
        surname: surname.optional(),
        fatherName: fatherName.nullable().optional(),
        position: positionSchema(polyglot).optional(),
        isMilitary: isMilitarySchema.optional(),
        email: emailSchema(polyglot).optional(),
        biography: biographySchema(polyglot).optional(),
        image: imageSchema(polyglot).optional(),
        birthDate: dateDtoSchema(polyglot).optional(),
        deathDate: dateDtoSchema(polyglot).optional(),
        phone: phoneDtoSchema(polyglot).optional(),
        hasMedal: hasMedalSchema.optional(),
        sex: sexSchema(polyglot).optional(),
    })
        .noUnknown((params) => `Наступні передані параметри зайві: ${params.unknown}`)
        .strict();
};
