import React from 'react';
import { TextField } from '../../TextField';
import { ApplicationQuill } from '../../ApplicationQuill/ApplicationQuill';
import { withLocale } from '../../withLocale/withLocale';

export const ArticleContent: React.FC = withLocale(({ locale }) => {
  return (
    <div className="mt-n1">
      <div className="mb-2">
        <TextField
          key={`content.${locale}.title`}
          name={`content.${locale}.title`}
          className="py-1 border-2"
          placeholder="Введіть заголовок..."
        />
      </div>

      <div className="mb-2">
        <ApplicationQuill
          key={`content.${locale}.text`}
          name={`content.${locale}.text`}
          placeholder="Введіть короткий опис події..."
        />
      </div>
    </div>
  );
});
