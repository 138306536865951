import React, { ComponentProps } from 'react';
import { useMutation } from 'react-query';
import { Formik, Form } from 'formik';
import { VictimStatus, changeVictimStatusSchema } from 'shared';

import { adminService } from '../../services/adminService';
import { changeVictimStatus } from '../../redux/features/victimsSlice';
import { useAppDispatch } from '../../redux/hook';
import { VictimStatusBadge } from '../VictimStatusBadge';

import { ChangeVictimStatusPayload } from '../../types/api/victim';
import { toastService } from '../../services/toastService';
import { AppError, isApiError } from '../../types/Error';
import { apiErrorService } from '../../services/apiErrorService';
import { changePlaqueStatus } from '../../redux/features/plaquesSlice';
import { IStatusChanger } from './StatusChanger.interface';
import { PreferredSlugField } from './PreferredSlugField';
import { ChangePlaqueStatusPayload } from '../../types/api/plaque';
import { StatusSwitcher } from './StatusSwitcher';

type Props = {
  id: number;
  initialStatus: VictimStatus;
  changeFor: 'victim' | 'plaque';
};

export const StatusChanger: React.FC<Props> = ({
  id,
  initialStatus,
  changeFor,
}) => {
  const dispatch = useAppDispatch();

  const { mutate: updateVictimStatus, isLoading: isVictimStatusUpdating }
    = useMutation<void, AppError, ChangeVictimStatusPayload>({
      mutationKey: ['changeVictimStatus'],
      mutationFn: async (payload: ChangeVictimStatusPayload) => {
        return adminService.changeVictimStatus(payload);
      },
      onSuccess: (_, payload) => {
        dispatch(changeVictimStatus(payload));
      },
      onError: (error) => {
        let description = 'Помилка при оновлені статусу заявки';

        if (
          isApiError(error)
          && error.response
          && error.response.data.errors.slug
        ) {
          description += `. ${error.response.data.errors.slug}`;
        }

        const message = apiErrorService.getMessage(error, description);

        toastService.error(message);
      },
      onMutate: () => toastService.dismiss(),
    });

  const { mutate: updatePlaqueStatus, isLoading: isPlaqueStatusUpdating }
    = useMutation<void, AppError, ChangePlaqueStatusPayload>({
      mutationKey: ['changePlaqueStatus', initialStatus],
      mutationFn: async (payload: ChangePlaqueStatusPayload) => {
        return adminService.changePlaqueStatus(payload);
      },
      onSuccess: (_, payload) => {
        dispatch(changePlaqueStatus(payload));
      },
      onError: (error) => {
        const message = apiErrorService.getMessage(
          error,
          'Помилка при оновлені статусу заявки',
        );

        toastService.error(message);
      },
      onMutate: () => toastService.dismiss(),
    });

  const isDowngradeWarningVisible = initialStatus === 'approved'
    && changeFor === 'victim';
  const isLoading = isPlaqueStatusUpdating || isVictimStatusUpdating;

  const onSubmit: ComponentProps<typeof Formik<IStatusChanger>>['onSubmit']
    = ({ slug, status }) => {
      if (changeFor === 'victim') {
        updateVictimStatus({ id, status, slug });
      } else {
        updatePlaqueStatus({ id, status });
      }
    };

  return (
    <Formik
      initialValues={{ slug: '', status: initialStatus }}
      enableReinitialize
      initialTouched={{ slug: true }}
      validationSchema={changeVictimStatusSchema}
      onSubmit={onSubmit}
    >
      {({ initialValues, values, resetForm }) => (
        <Form>
          <StatusSwitcher name="status" isLoading={isLoading} />

          {initialValues.status !== values.status && (
            <div className="mt-4">
              <p>
                Зміна статусу:

                <VictimStatusBadge
                  status={initialStatus}
                  className="ms-2"
                />

                <i className="bi bi-arrow-right align-middle fs-5 lh-1 ms-2" />

                <VictimStatusBadge
                  status={values.status}
                  className="ms-2"
                />
              </p>

              {changeFor === 'victim' && values.status === 'approved' && (
                <div className="col-12 col-xxl-6 mt-4 mb-3">
                  <PreferredSlugField
                    name="slug"
                    victimId={id}
                  />
                </div>
              )}

              <div className="row mb-3">
                {isDowngradeWarningVisible && (
                  <div className="col">
                    <div
                      className="alert alert-warning d-flex
                        align-items-center p-0 ps-2 h-100"
                      role="alert"
                    >
                      <i className="bi bi-exclamation-triangle me-2" />
                      У такому випадку сторінку з людиною буде
                      вилучено з публічного доступу
                    </div>
                  </div>
                )}
              </div>

              <button
                type="submit"
                className="btn btn-primary me-3"
                disabled={isLoading}
              >
                Підтвердити
              </button>

              <button
                type="button"
                className="btn btn-outline-primary"
                disabled={isLoading}
                onClick={() => resetForm({ status: initialStatus })}
              >
                Скасувати
              </button>
            </div>
          )}
        </Form>
      )}
    </Formik>
  );
};
