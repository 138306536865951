import React, { useCallback } from 'react';
import cn from 'classnames';
import { useMutation } from 'react-query';

import { IArticle } from '../../types/article';
import { Picture } from '../Picture';
import { useAppDispatch, useAppSelector } from '../../redux/hook';
import {
  deleteArticle,
  selectArticle,
} from '../../redux/features/articlesSlice';
import { adminService } from '../../services/adminService';
import { PopConfirm } from '../PopConfirm';
import { toastService } from '../../services/toastService';
import { apiErrorService } from '../../services/apiErrorService';
import { AppError } from '../../types/Error';
import { useResolvedLanguage } from '../../hooks/useResolvedLanguage';

type Props = {
  article: IArticle;
};

export const ArticlesItem: React.FC<Props> = React.memo(({ article }) => {
  const { selectedArticleId } = useAppSelector(state => state.articles);
  const dispatch = useAppDispatch();
  const locale = useResolvedLanguage();

  const deleteArticleMutation = useMutation<void, AppError, number>({
    mutationKey: ['article', article.id],
    mutationFn: async (id) => {
      await adminService.deleteArticle(id);
    },
    onSuccess: async () => {
      dispatch(deleteArticle(article.id));
      toastService.success({ title: 'Подію видалено успішно' });
    },
    onError: (error) => {
      const message = apiErrorService.getMessage(
        error, 'Не вдалось видалити подію',
      );

      toastService.error(message);
    },
    onMutate: () => toastService.dismiss(),
  });

  const handleArticleDelete = useCallback(() => {
    deleteArticleMutation.mutate(article.id);
  }, [deleteArticleMutation]);

  return (
    <div
      role="button"
      className={cn(
        'row d-flex btn btn-light w-100 m-0 mb-3 py-2 px-1',
        'rounded border border-2 position-relative',
        {
          'pe-none opacity-50': deleteArticleMutation.isLoading,
        },
      )}
      style={{
        backgroundColor: selectedArticleId === article.id ? '#bfbfbf' : '',
      }}
      onClick={() => dispatch(selectArticle(article.id))}
      onKeyDown={() => { }}
      tabIndex={0}
    >
      <div className="d-block d-md-none d-lg-block col-1 p-0 p-lg-2">
        <Picture
          className="table-image rounded-circle"
          src={article.image}
        />
      </div>

      <div className="ms-3 ms-md-0 col-7">
        <p className="text-start">
          {article.content[locale].title || article.content.ua.title}
        </p>
      </div>

      <div className="col-1 d-flex align-items-center">
        <span>{new Date(article.date).toLocaleDateString('uk')}</span>
      </div>

      <div className="col-3 d-flex justify-content-end align-items-center">
        <PopConfirm onConfirm={handleArticleDelete}>
          {(togglePopover) => (
            <button
              type="button"
              className="btn btn-secondary p-1 ms-3"
              onClick={(event) => {
                event.stopPropagation();
                togglePopover();
              }}
            >
              <i className="bi bi-x-circle fs-3 lh-1 align-middle" />
            </button>
          )}
        </PopConfirm>
      </div>

      {deleteArticleMutation.isLoading && (
        <div className="position-absolute top-0 bottom-0 start-0 end-0
          d-flex align-items-center justify-content-center"
        >
          <div className="spinner-border" role="status">
            <span className="visually-hidden">Loading...</span>
          </div>
        </div>
      )}
    </div>
  );
});
