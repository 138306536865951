/* eslint-disable max-len */
import { httpClient } from '../client/httpClient';
import {
  LoginInputPayload,
  LoginOutputPayload,
  RefreshInputPayload,
  RefreshOutputPayload,
} from '../types/api/auth';

export async function login(payload: LoginInputPayload): Promise<LoginOutputPayload> {
  return httpClient.post('/login', payload);
}

export async function refresh(payload: RefreshInputPayload): Promise<RefreshOutputPayload> {
  return httpClient.post('/refresh', payload);
}

export const authService = {
  login,
  refresh,
};
