/* eslint-disable max-len */
// eslint-disable-next-line object-curly-newline
import React, { ComponentProps, useCallback, useEffect, useMemo, useRef } from 'react';
// eslint-disable-next-line object-curly-newline
import { Formik, Form, Field, FormikErrors, FormikProps } from 'formik';
import { IVictimFull, Locale, createVictimFormSchema } from 'shared';
import { useMutation } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { updatedDiff } from 'deep-object-diff';
import { useTranslation } from 'react-i18next';

import { ApplicationDatePicker } from '../ApplicationDatePicker';
import { ApplicationQuill } from '../ApplicationQuill/ApplicationQuill';
import { ApplicationImageUploader } from '../ApplicationImageUploader';
import { TextField } from '../TextField';
import { VictimForm as VictimFormType } from '../../types/VictimForm';
import { publicService } from '../../services/publicService';
import { AppError, isApiError } from '../../types/Error';
import { apiErrorService } from '../../services/apiErrorService';
import { toastService } from '../../services/toastService';
import { getVictimFormValues } from '../../helpers/getVictimFormValues';
import { adminService } from '../../services/adminService';
import { useAppDispatch } from '../../redux/hook';
import { updateVictim } from '../../redux/features/victimsSlice';
import { Toggler } from '../Toggler';
import { FormActionButtonGroup } from '../FormActionButtonGroup';
import { ContactsFieldGroup } from '../ContactsFieldGroup';

const initialVictimValues: VictimFormType = {
  name: '',
  surname: '',
  fatherName: '',
  birthDate: null,
  deathDate: null,
  noDeathDate: false,
  isMilitary: true,
  position: '',
  biography: '',
  image: null,
  email: '',
  phone: '',
  hasMedal: false,
  sex: 'male',
};

type CreateVariables = {
  payload: VictimFormType,
  setErrors: (errors: FormikErrors<VictimFormType>) => void;
};

type UpdateVariables = {
  id: number;
  payload: Partial<VictimFormType>;
  setErrors: (errors: FormikErrors<VictimFormType>) => void;
};

type Props = {
  currentVictim?: IVictimFull;
  locale: Locale,
};

export const VictimForm: React.FC<Props> = ({ currentVictim, locale }) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const formikRef = useRef<FormikProps<VictimFormType>>(null);

  const { t: tLabels } = useTranslation('victim', { keyPrefix: 'labels', lng: locale });
  const { t: tPlaceholders } = useTranslation('victim', { keyPrefix: 'placeholders', lng: locale });
  const { t: tCb } = useTranslation('victim', { keyPrefix: 'checkBoxLabels', lng: locale });
  const { t: tMessages } = useTranslation('victim', { keyPrefix: 'messages', lng: locale });

  const victimFormSchema = useMemo(() => {
    return createVictimFormSchema(locale);
  }, [locale]);

  useEffect(() => {
    if (formikRef.current) {
      formikRef.current.validateForm();
    }
  }, [locale]);

  const { mutate: create, isLoading: isCreating } = useMutation<void, AppError, CreateVariables>({
    mutationFn: async ({ payload }) => {
      await publicService.createApplication(payload, locale);
    },
    onError: (err, { setErrors }) => {
      const message = apiErrorService.getMessage(err, tMessages('createError'), locale);

      toastService.error(message, {
        toastId: 'create-application-error',
      });

      if (isApiError(err) && err.response) {
        setErrors(err.response.data.errors);
      }
    },
    onSuccess: () => {
      toastService.success({
        title: tMessages('createSuccess'),
        description: tMessages('more'),
      });
      navigate('/');
    },
    onMutate: () => {
      toastService.dismiss();
    },
  });

  const { mutate: update, isLoading: isUpdating } = useMutation<IVictimFull, AppError, UpdateVariables>({
    mutationFn: async ({ id, payload }) => {
      return adminService.updateVictim(id, payload, locale);
    },
    onSuccess: (article) => {
      dispatch(updateVictim(article));
      toastService.success({ title: tMessages('updateSuccess') });
    },
    onError: (error, { setErrors }) => {
      const message = apiErrorService.getMessage(
        error, tMessages('updateError'), locale,
      );

      if (isApiError(error) && error.response) {
        setErrors(error.response.data.errors);
      }

      toastService.error(message);
    },
    onMutate: () => toastService.dismiss(),
  });

  const initialValues: VictimFormType = useMemo(() => (
    currentVictim
      ? getVictimFormValues(currentVictim, locale)
      : initialVictimValues
  ), [currentVictim, locale]);

  const onSubmit: ComponentProps<typeof Formik<VictimFormType>>['onSubmit']
    = useCallback((values, { setErrors }) => {
      if (currentVictim) {
        const diff
          = updatedDiff(initialValues, values) as Partial<VictimFormType>;

        if (Object.keys(diff).length < 1) {
          toastService.articlesToast.warning(
            { title: 'Жодне з полів не було змінено' },
          );

          return;
        }

        update({ id: currentVictim.id, payload: diff, setErrors });
      } else {
        create({ payload: values, setErrors });
      }
    }, [currentVictim, create]);

  return (
    <div className="container mb-4 mb-xl-3">
      <Formik
        innerRef={formikRef}
        enableReinitialize
        initialValues={initialValues}
        validationSchema={victimFormSchema}
        onSubmit={onSubmit}
      >
        {({ values, setValues, resetForm }) => (
          <Form className="mt-n1">
            <section className="mb-3 mb-lg-1">
              <h3 className="ms-2">{tLabels('name')}</h3>
              <div className="row">
                <div className="col-12 col-sm-6 col-lg-4">
                  <TextField
                    name="surname"
                    placeholder={tPlaceholders('surname')}
                  />
                </div>

                <div className="col-12 col-sm-6 col-lg-4">
                  <TextField
                    name="name"
                    placeholder={tPlaceholders('name')}
                  />
                </div>

                <div className="col-12 col-sm-6 col-lg-4">
                  <TextField
                    name="fatherName"
                    placeholder={tPlaceholders('fatherName')}
                  />
                </div>
              </div>
            </section>

            <section className="mb-3 mb-lg-1">
              <h3 className="ms-2">{tLabels('yearsOfLife')}</h3>
              <div className="row">
                <div className="col-12 col-sm-6 col-lg-4">
                  <ApplicationDatePicker name="birthDate" />
                </div>

                <div className="col-12 col-lg-8">
                  <div className="row">
                    <div className="col-12 col-sm-6 col-lg-6 order-5 order-sm-1">
                      <ApplicationDatePicker
                        name="deathDate"
                        disabled={values.noDeathDate}
                      />
                    </div>

                    <div className="col-12 col-sm-6 col-lg-6 order-1 order-sm-5 py-2">
                      <div className="form-check m-0">
                        <Field
                          className="form-check-input"
                          name="noDeathDate"
                          type="checkbox"
                          id="noDeathDate"
                          onChange={() => {
                            setValues(prevState => {
                              const noDeathDate = !prevState.noDeathDate;

                              return {
                                ...prevState,
                                noDeathDate,
                                deathDate: noDeathDate ? null : prevState.deathDate,
                              };
                            });
                          }}
                        />
                        <label className="form-check-label fs-5" htmlFor="noDeathDate">
                          {tCb('noDeathDate')}
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>

            <section className="mb-3 mb-lg-1">
              <h3 className="ms-2">{tLabels('occupation')}</h3>
              <div className="row">
                <div className="col-12 col-lg-8">
                  <TextField
                    name="position"
                    placeholder={tPlaceholders('occupation')}
                  />
                </div>

                <div className="col-12 col-md-6 col-lg-4 mt-2">
                  <Toggler
                    name="isMilitary"
                    firstOption={{
                      value: true,
                      text: tCb('military'),
                    }}
                    secondOption={{
                      value: false,
                      text: tCb('civilian'),
                    }}
                  />
                </div>
              </div>
            </section>

            <section>
              <div className="row">
                <div className="col-12 col-md-8 order-5 order-md-1">
                  <h3 className="ms-2">{tLabels('biography')}</h3>

                  <ApplicationQuill
                    name="biography"
                    placeholder={tPlaceholders('biography')}
                  />

                  <ContactsFieldGroup locale={locale} />
                </div>

                <div className="col-12 col-md-4 order-1 order-md-5 mb-3">
                  <h3 className="ms-2">{tLabels('photo')}</h3>

                  <ApplicationImageUploader
                    name="image"
                    whiteStyle={!currentVictim}
                    image={currentVictim?.image}
                  />
                </div>
              </div>
            </section>

            {currentVictim && (
              <section className="row mt-3 mt-lg-1">
                <div className="col-12 col-md-3">
                  <h3 className="ms-2">{tLabels('hasMedal')}</h3>

                  <Toggler
                    name="hasMedal"
                    firstOption={{
                      value: true,
                      text: tCb('hasMedal'),
                    }}
                    secondOption={{
                      value: false,
                      text: tCb('noMedal'),
                    }}
                  />
                </div>

                <div className="col-12 col-md-3">
                  <h3 className="ms-2">{tLabels('sex')}</h3>

                  <Toggler
                    name="sex"
                    firstOption={{
                      value: 'male',
                      text: tCb('male'),
                    }}
                    secondOption={{
                      value: 'female',
                      text: tCb('female'),
                    }}
                  />
                </div>
              </section>
            )}

            <div className="mt-xl-n1">
              <FormActionButtonGroup
                isForUpdating={Boolean(currentVictim)}
                resetForm={resetForm}
                isCreating={isCreating}
                isUpdating={isUpdating}
                locale={locale}
              />
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};
