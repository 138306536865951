import React from 'react';
import { AnimatePresence, motion } from 'framer-motion';
import { useAppSelector } from '../../../redux/hook';
import './PulseLoader.css';

export const PulseLoader: React.FC = () => {
  const { isVisible } = useAppSelector(state => state.backdrop);

  return (
    <AnimatePresence initial={false}>
      {isVisible && (
        <motion.div
          key="modal"
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          transition={{ duration: 0.5 }}
          className="vh-100 bg-black position-absolute start-0 end-0
          d-flex align-items-center justify-content-center z-3"
        >
          <div className="pulse" />
        </motion.div>
      )}
    </AnimatePresence>
  );
};
