import { convertLocale } from './convertLocale';

export function getUkraineDate(
  date: string,
  locale: string | undefined,
): { date: string, year: string } {
  const dateLocale = convertLocale(locale);

  return {
    date: new Intl.DateTimeFormat(dateLocale, {
      month: 'long',
      day: 'numeric',
    }).format(new Date(date)),
    year: new Intl.DateTimeFormat(dateLocale, {
      year: 'numeric',
    }).format(new Date(date)),
  };
}
