export const VICTIM_VALIDATION_MSG = {
    ua: {
        namePart: {
            surname: 'Прізвище',
            firstName: "Ім'я",
            fatherName: 'По батькові',
        },
        name: {
            required: "{{namePart}} обов'язкове",
            symbols: '{{namePart}} повинно містити лише '
                + 'кирилицю, апостроф, тире та пробіли',
            min: '{{namePart}} повинно містити принаймні 2 символи',
            max: '{{namePart}} може містити максимум 50 символів',
        },
        position: {
            required: "Опис діяльності людини обов'язковий",
            max: 'Опис діяльності повинен містити не більше 250 символів',
        },
        email: {
            required: "Електронна пошта обов'язкова",
            wrongFormat: 'Некоректна електронна пошта',
        },
        biography: {
            required: 'Напишіть коротку біографію',
            max: 'Біографія повинна містити не більше 65000 символів',
        },
        photo: {
            required: "Фотографія людини обов'язкова",
        },
        date: {
            required: 'Вкажіть коректну дату',
            deathAfterBirth: 'Дата смерті повинна бути після дати народження',
            wrongDateString: 'Рядок неможливо перевести у дату',
        },
        phone: {
            wrongFormat: 'Некоректний номер телефону',
        },
        sex: {
            required: "Стать обов'язкова",
            oneOf: 'Стать повинна бути однією із {{sexes}}',
        },
    },
    en: {
        namePart: {
            surname: 'Surname',
            firstName: 'First name',
            fatherName: "Father's name",
        },
        name: {
            required: '{{namePart}} is required',
            symbols: '{{namePart}} must contain only '
                + 'Latin characters, apostrophe, dash and spaces',
            min: '{{namePart}} must contain at least 2 characters',
            max: '{{namePart}} can contain maximum of 50 characters',
        },
        position: {
            required: 'Description of the occupation is required',
            max: 'Description of the occupation '
                + 'must contain no more than 250 characters',
        },
        email: {
            required: 'Email is required',
            wrongFormat: 'Invalid email format',
        },
        biography: {
            required: 'Write a short biography',
            max: 'Biography can contain maximum of 65000 characters',
        },
        photo: {
            required: 'Photo of the person is required',
        },
        date: {
            required: 'Specify a valid date',
            requiredBirth: 'Specify a valid date of birth',
            deathAfterBirth: 'Date of death must be after date of birth',
            wrongDateString: 'Cannot convert string to date',
        },
        phone: {
            wrongFormat: 'Invalid phone number format',
        },
        sex: {
            required: 'Sex is required',
            oneOf: 'Sex must be one of {{sexes}}',
        },
    },
};
