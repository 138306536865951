/* eslint-disable max-len */
import React, { useEffect, useState } from 'react';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';

import { FilePond, registerPlugin, FilePondProps } from 'react-filepond';

import 'filepond/dist/filepond.min.css';

import FilePondPluginImageExifOrientation from 'filepond-plugin-image-exif-orientation';
import FilePondPluginImagePreview from 'filepond-plugin-image-preview';
import FilePondPluginFileValidateSize from 'filepond-plugin-file-validate-size';
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type';

import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css';
import './ApplicationImageUploader.css';

import { FilePondInitialFile } from 'filepond';
import { useField } from 'formik';
import { withErrorMessage } from '../withErrorMessage';

// Register the plugins
registerPlugin(
  FilePondPluginImageExifOrientation,
  FilePondPluginImagePreview,
  FilePondPluginFileValidateSize,
  FilePondPluginFileValidateType,
);

type Props = {
  name: string;
  whiteStyle?: boolean;
  image?: string | null;
} & FilePondProps;

export const ApplicationImageUploader: React.FC<Props> = withErrorMessage(({
  name,
  whiteStyle,
  image,
  ...props
}) => {
  const [files, setFiles] = useState<(FilePondInitialFile | Blob | string)[]>([]);
  const { t } = useTranslation('uploader');

  useEffect(() => {
    setFiles(image
      ? [{ source: image, options: { type: 'local' } }]
      : []);
  }, [image]);
  const [, , helpers] = useField<string | null>(name);

  return (
    <FilePond
      files={files}
      name={name}
      className={cn({ 'filepond--white': whiteStyle })}
      imagePreviewMaxHeight={250}
      maxFileSize="5MB"
      labelMaxFileSizeExceeded={t('maxFileSizeExceeded')}
      labelMaxFileSize={t('maxFileSize', { maxSize: '5MB' })}
      acceptedFileTypes={['image/*']}
      labelFileTypeNotAllowed={t('fileTypeNotAllowed')}
      fileValidateTypeLabelExpectedTypes={t('fileValidateTypeExpectedTypes')}
      labelIdle={`<p class="text-secondary mb-0 fs-5">
        ${t('labelIdleDrag')} <span class="filepond--label-action">${t('labelIdleSelect')}</span>
      </p>`}
      labelFileProcessing={t('fileProcessing')}
      labelFileProcessingError={t('fileProcessingError')}
      labelTapToRetry={t('tapToRetry')}
      labelTapToUndo={t('tapToUndo')}
      labelFileProcessingComplete={t('fileProcessingComplete')}
      labelTapToCancel={t('tapToCancel')}
      server={{
        url: process.env.REACT_APP_API_URL,
        process: '/upload',
        revert: '/upload',
        load: async (source, load, error) => {
          const route = `${process.env.REACT_APP_FILES_URL}/${source}`;

          try {
            const response = await fetch(route);
            const file = await response.blob();

            load(file);
          } catch {
            error(t('uploadErrorMessage'));
          }
        },
      }}
      // onupdatefiles включает всё - abort, revert, remove
      // единственное, он не ждёт пока придёт serverId для файла
      // поэтому добавляем ещё и onprocessfile
      onupdatefiles={(newFiles) => {
        setFiles(newFiles.map(fp => fp.file));
        helpers.setValue(newFiles[0]?.serverId ?? null);
      }}
      onprocessfile={(_, file) => helpers.setValue(file.serverId)}
      {...props}
    />
  );
});
