import React from 'react';
import Skeleton from 'react-loading-skeleton';

import './PublicVictimItem.css';

export const PublicVictimSkeleton: React.FC = () => {
  return (
    <div
      className="col-6 col-md-4 col-lg-3 col-xxl-2 mb-4
      d-flex justify-content-center align-items-center"
    >
      <span className="public-victim-item__container cursor-pointer rounded-5">
        <Skeleton
          className="public-victim-item__image rounded-5"
          style={{ position: 'absolute' }}
        />
      </span>
    </div>
  );
};
