import React from 'react';
import './AdminPanelLayout.css';

type Props = {
  leftPanel: React.ReactNode;
  rightPanel: React.ReactNode;
};

export const AdminPanelLayout: React.FC<Props> = ({
  leftPanel,
  rightPanel,
}) => {
  return (
    <div className="row gx-2 w-100 h-100">
      <div className="col-12 mb-3 mb-md-0 col-md-6">
        <div className="p-3 shadow border">
          {leftPanel}
        </div>
      </div>

      <div
        className="col-12 col-md-6 border shadow
        admin-panel-layout__sticky z-0"
      >
        <div className="p-3">
          {rightPanel}
        </div>
      </div>
    </div>
  );
};
