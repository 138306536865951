import React from 'react';
import cn from 'classnames';
import { VictimStatus } from 'shared';
import {
  getVictimStatusProps,
} from '../../helpers/getVictimStatusProps';

type Props = {
  status: VictimStatus;
  isSelected: boolean;
  isDisabled: boolean;
  isLoading: boolean;
} & React.ButtonHTMLAttributes<HTMLButtonElement>;

export const StatusButton: React.FC<Props> = ({
  status,
  isSelected,
  isDisabled,
  isLoading,
  ...props
}) => {
  const { text, outlinedClass, filledClass }
    = getVictimStatusProps(status, true);

  return (
    <button
      type="button"
      className={cn(
        'btn',
        'me-sm-2',
        {
          [outlinedClass]: !isSelected,
          [filledClass]: isSelected,
          disabled: isDisabled,
        },
      )}
      {...props}
    >
      {isLoading && (
        <span
          className="spinner-border spinner-border-sm"
          role="status"
          aria-hidden="true"
        />
      )}
      &nbsp;
      {text}
    </button>
  );
};
