import React from 'react';
import { Link } from 'react-router-dom';
import { IVictimBase } from 'shared';
import { LazyLoadImage } from 'react-lazy-load-image-component';

import medalImage from '../../assets/images/hero-medal.png';
import './PublicVictimItem.css';

type Props = {
  victim: IVictimBase;
};

export const PublicVictimItem: React.FC<Props> = ({
  victim: {
    slug,
    image,
    hasMedal,
  },
}) => {
  return (
    <div
      className="col-6 col-md-4 col-lg-3 col-xxl-2 mb-4
      d-flex justify-content-center align-items-center"
    >
      <Link
        to={`/${slug}`}
        className="public-victim-item__container rounded-5 position-relative"
      >
        <LazyLoadImage
          threshold={200}
          effect="blur"
          src={`${process.env.REACT_APP_FILES_URL}/${image}`}
          alt={`Hero ${slug}`}
          wrapperClassName="public-victim-item__lazy-wrapper"
          className="public-victim-item__image rounded-5"
          useIntersectionObserver
        />

        {hasMedal && (
          <LazyLoadImage
            src={medalImage}
            effect="blur"
            alt="Medal"
            className="p-1"
            wrapperClassName="medal"
            width={50}
            height={50}
          />
        )}
      </Link>
    </div>
  );
};
