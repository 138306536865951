import React, { useCallback } from 'react';
import { iconColors } from 'shared';
import { useField } from 'formik';
import cn from 'classnames';

import './ColorPicker.css';
import { withErrorMessage } from '../withErrorMessage';

type Props = {
  name: string;
};

export const ColorPicker: React.FC<Props> = withErrorMessage(({
  name,
}) => {
  const [, meta, helpers] = useField(name);

  const { value } = meta;
  const { setValue } = helpers;

  const isSelected = useCallback((color: string) => (
    color === value
  ), [value]);

  return (
    <div
      className="color-picker__color-picker p-2 rounded"
      style={{ backgroundColor: '#E1E1E1' }}
    >
      {Object.values(iconColors).map(color => (
        <button
          key={color}
          type="button"
          aria-label="Pick a color"
          style={{ backgroundColor: color }}
          className={cn(
            'd-inline-block',
            'align-middle',
            'color-picker__color',
            {
              'color-picker__color--selected': isSelected(color),
            },
          )}
          onClick={() => setValue(color)}
        />
      ))}
    </div>
  );
});
