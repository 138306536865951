import * as Yup from 'yup';
const positiveNumberSchema = Yup
    .number()
    .integer()
    .min(0);
export const getAllVictimsParamsSchema = Yup
    .object()
    .shape({
    isMilitary: Yup.boolean(),
    query: Yup.string(),
    limit: positiveNumberSchema,
    offset: positiveNumberSchema,
})
    .noUnknown();
