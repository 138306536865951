import { useMemo } from 'react';
import { Locale } from 'shared';
import { useTranslation } from 'react-i18next';

export const useResolvedLanguage = () => {
  const { i18n: { resolvedLanguage } } = useTranslation();
  const locale = useMemo(() => (
    (resolvedLanguage ?? 'ua') as Locale
  ), [resolvedLanguage]);

  return locale;
};
