import React from 'react';
import { Outlet } from 'react-router-dom';
import { Header } from '../Header';

export const MainLayout: React.FC = () => {
  return (
    <div className="min-vh-100 bg-white d-flex flex-column">
      <Header className="mb-3 sticky-top" />

      <main className="container-fluid flex-grow-1 d-flex pb-4">
        <Outlet />
      </main>
    </div>
  );
};
