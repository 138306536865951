import React from 'react';
import { useTranslation } from 'react-i18next';
import footerImage from '../../assets/images/footer.svg';
import './Footer.css';

export const Footer: React.FC = () => {
  const { t } = useTranslation('main');

  return (
    <div className="p-3 pb-md-4 pb-md-5 d-flex
      justify-content-center align-items-center"
    >
      <a
        href="https://ednipro.dp.ua"
        target="_blank"
        rel="noreferrer"
        className="px-4 py-3 p-md-3 border text-light footer-link
        border-3 rounded-pill border-light"
      >
        <span className="d-none d-md-inline-block me-3
          align-middle fs-3"
        >
          {t('developedBy')}
        </span>
        <img
          src={footerImage}
          alt="Developed By EDnipro"
        />
      </a>
    </div>
  );
};
