/* eslint-disable max-len */
import { omitBy, isUndefined } from 'lodash';
import { IPlaque, IVictim, Locale } from 'shared';
import queryString from 'query-string';
import { httpClient } from '../client/httpClient';
import {
  CreateApplicationPayload,
  GetPublicVictimsInputPayload,
  GetPublicVictimsOutputPayload,
} from '../types/api/victim';
import { IArticle } from '../types/article';
import { CreatePlaquePayload } from '../types/api/plaque';

async function getVictims(payload: GetPublicVictimsInputPayload): Promise<GetPublicVictimsOutputPayload> {
  const queryParams = queryString.stringify(payload);

  return httpClient.get(`/victims/public?${queryParams}`);
}

async function getVictimBySlug(slug: string, locale?: string): Promise<IVictim> {
  return httpClient.get(`/victims/${slug}?locale=${locale}`);
}

async function getArticles(): Promise<IArticle[]> {
  return httpClient.get('/articles');
}

async function createApplication(payload: CreateApplicationPayload, locale: Locale) {
  const {
    noDeathDate, // omit
    hasMedal, // omit
    sex, // omit
    image,
    phone,
    fatherName,
    ...victimProps
  } = payload;

  return httpClient.post(`/victims/public?locale=${locale}`, {
    ...victimProps,
    image: image ?? null,
    phone: phone || null,
    fatherName: fatherName || null,
  });
}

async function createPlaque(
  payload: CreatePlaquePayload,
  locale: Locale,
): Promise<IPlaque> {
  const { victim, phone, ...data } = payload;

  return httpClient.post(
    `/plaques?locale=${locale}`,
    {
      ...omitBy(data, isUndefined),
      victimId: victim?.value,
      phone: phone || null,
    },
  );
}

export const publicService = {
  getVictims,
  getVictimBySlug,
  createApplication,
  createPlaque,
  getArticles,
};
