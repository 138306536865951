import React from 'react';
import { Locale } from 'shared';
import { useTranslation } from 'react-i18next';
import { TextField } from '../TextField';
import { ApplicationPhoneField } from '../ApplicationPhoneField';

type Props = {
  locale: Locale;
};

export const ContactsFieldGroup: React.FC<Props> = ({ locale }) => {
  const { t: tLabels }
    = useTranslation('victim', { keyPrefix: 'labels', lng: locale });
  const { t: tPlaceholders }
    = useTranslation('victim', { keyPrefix: 'placeholders', lng: locale });

  return (
    <div className="row">
      <div className="col-12 col-lg-6">
        <h3 className="ms-2">{tLabels('email')}</h3>

        <TextField
          formNoValidate
          type="email"
          autoComplete="on"
          name="email"
          placeholder={tPlaceholders('email')}
        />
      </div>

      <div className="col-12 col-lg-6">
        <h3 className="ms-2">
          <span className="d-none d-lg-inline d-xl-none">
            {tLabels('phoneShort')}
          </span>
          <span className="d-lg-none d-xl-inline">
            {tLabels('phoneLong')}
          </span>
        </h3>

        <ApplicationPhoneField
          name="phone"
          placeholder={tPlaceholders('phone')}
        />
      </div>
    </div>
  );
};
