import React from 'react';
import { IVictim } from 'shared';
import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import medalImage from '../../assets/images/hero-medal.png';

import './VictimInfoPage.css';

import { publicService } from '../../services/publicService';
import { Picture } from '../../components/Picture';
import { getFullName } from '../../helpers/getFullName';
import { getDateInterval } from '../../helpers/getDateInterval';

export const VictimInfoPage: React.FC = () => {
  const { slug = '' } = useParams();
  const { t, i18n: { resolvedLanguage } } = useTranslation('hero');

  // eslint-disable-next-line object-curly-newline
  const { data: victim, isLoading, isError, isSuccess }
    = useQuery<IVictim, unknown>({
      queryKey: ['victim', slug, resolvedLanguage],
      queryFn: async () => {
        const victims = await publicService.getVictimBySlug(
          slug,
          resolvedLanguage,
        );

        return victims;
      },
    });

  return (
    <div className="victim-info-page-container">
      {isLoading && (
        <div className="spinner-border d-block m-auto" role="status">
          <span className="visually-hidden">Loading...</span>
        </div>
      )}

      {
        isError && (
          <div className="alert alert-danger d-block m-auto" role="alert">
            Помилка під час завантаження людини
          </div>
        )
      }

      {isSuccess && (
        <>
          <div className="row gx-xl-5">
            <div className="col-12 mb-3 col-lg-4 position-relative">
              <Picture
                className="victim-info-page-image
                m-auto d-block image-container"
                src={victim.image}
                alt="Portrait"
              />

              {victim.hasMedal && (
                <LazyLoadImage
                  src={medalImage}
                  effect="blur"
                  alt="Medal"
                  className="p-1"
                  wrapperClassName="medal2"
                />
              )}
            </div>

            <div className="col-12 col-lg-8">
              <div className="bg-light rounded-pill p-3 p-lg-4
                text-center mb-3"
              >
                <h4 className="m-0 fw-semibold display-6
                  lh-base victim-info-page-title"
                >
                  {getFullName(victim)}
                  <br />
                  {getDateInterval(victim, resolvedLanguage)}
                </h4>
              </div>

              {victim.isMilitary && (
                <h2 className="m-0 display-5 mb-3 ps-2">
                  {t('gaveLife', { context: victim.sex })}
                </h2>
              )}

              <p className="fs-2 lh-sm ps-2">{victim.position}</p>
            </div>

            <h2 className="display-6 fw-semibold mt-3">
              {t('biography')}
            </h2>

            <div
              className="mt-3 fs-4"
              dangerouslySetInnerHTML={{ __html: victim.biography }}
            />

            {victim.isMilitary && (
              <span className="mt-3 fs-4 text-uppercase">
                {t('eternalGlory')}
              </span>
            )}
          </div>
        </>
      )}
    </div>
  );
};
