/* eslint-disable max-len */
import React from 'react';
import cn from 'classnames';
import { useNavigate } from 'react-router-dom';
import { IVictimFull } from 'shared';
import { getFullName } from '../../helpers/getFullName';
import { VictimStatusBadge } from '../VictimStatusBadge';
import { Picture } from '../Picture';

type Props = {
  victim: IVictimFull;
  isSelected: boolean;
};

export const VictimItem: React.FC<Props> = React.memo(({ victim, isSelected }) => {
  const navigate = useNavigate();

  return (
    <tr
      className={cn(
        'cursor-pointer',
        {
          'table-active': isSelected,
        },
      )}
      onClick={() => navigate(`/admin/victims/${victim.id}`)}
    >
      <td>
        <div className="d-flex align-items-center">
          <Picture
            src={victim.image}
            alt="Victim"
            className="table-image rounded-circle"
          />

          <p className="fw-bold m-0 ms-3">
            {getFullName(victim)}
          </p>
        </div>
      </td>

      <td>
        {victim.isMilitary ? (
          <span className="fw-normal mb-1">
            <i className="bi bi-shield" />
            &nbsp;Військовий
          </span>
        ) : (
          <span className="fw-normal mb-1">
            <i className="bi bi-peace" />
            &nbsp;Цивільний
          </span>
        )}
      </td>

      <td>
        <VictimStatusBadge status={victim.status} />
      </td>

      <td>
        {new Date(victim.createdAt).toLocaleDateString('ru-RU')}
      </td>

      <td>
        {!victim.email ? (
          <span>-</span>
        ) : (
          <a
            href={`mailto:${victim.email}`}
            style={{ maxWidth: 100 }}
            className="btn btn-link btn-sm btn-rounded text-truncate p-0"
          >
            {victim.email}
          </a>
        )}
      </td>
    </tr>
  );
});
