import { IPlaque } from 'shared';
import { TPlaqueForm } from '../types/plaque';
import { getFullName } from './getFullName';

export function getPlaqueFormValues(plaque: IPlaque): TPlaqueForm {
  return {
    isHero: plaque.isHero,
    victim: {
      value: plaque.victim.id,
      label: getFullName(plaque.victim),
      image: plaque.victim.image,
      isMilitary: plaque.victim.isMilitary,
    },
    image: plaque.image,
    callSign: plaque.callSign ?? '',
    unit: plaque.unit ?? '',
    address: plaque.address,
    email: plaque.email,
    phone: plaque.phone ?? undefined,
  };
}
