import { Locale } from 'shared';
import { IArticle } from '../types/article';

export function filterArticles(
  articles: IArticle[],
  queryParam: string | null,
  locale: Locale,
): Array<IArticle> {
  const query = (queryParam ?? '').toLowerCase().trim();

  return articles.filter((article) => {
    const title = article.content[locale].title.toLowerCase().trim();

    return title.includes(query);
  });
}
