import React, { useEffect, useState } from 'react';
import cn from 'classnames';
import { useField, Field } from 'formik';
import { useQuery } from 'react-query';
import { UniqueSlugResult } from 'shared';
import { useDebounce, useCounter } from '@uidotdev/usehooks';

import { LoadingOverlay } from '../../LoadingOverlay';
import { adminService } from '../../../services/adminService';
import { PREFERRED_SLUG_DEBOUNCE_MS } from '../../../constants/main';
import { useDidUpdateEffect } from '../../../hooks/useDidUpdateEffect';
import { toastService } from '../../../services/toastService';
import { AppError, isApiError } from '../../../types/Error';
import { apiErrorService } from '../../../services/apiErrorService';

type Props = {
  name: string;
  victimId: number;
};

export const PreferredSlugField: React.FC<Props> = ({
  victimId,
  name,
}) => {
  const [, meta, helpers] = useField<string>(name);
  const [changeNo, { increment }] = useCounter(0);

  const { value: slug, error, touched } = meta;
  const { setValue: setSlug, setError } = helpers;
  const [slugForCheck, setSlugForCheck] = useState(slug);

  useDidUpdateEffect(() => {
    increment();
  }, [slug]);

  const debouncedSlug = useDebounce(
    slug,
    PREFERRED_SLUG_DEBOUNCE_MS,
  );

  useEffect(() => {
    if (changeNo !== 1 && !error) {
      setSlugForCheck(slug);
    }
  }, [debouncedSlug]);

  const {
    isSuccess,
    isFetching,
    data,
  }
    = useQuery<UniqueSlugResult, AppError>({
      queryKey: ['preferred-slug', victimId, slugForCheck],
      queryFn: async () => {
        const result = await adminService.getPreferredSlug({
          victimId, slug: slugForCheck, locale: 'ua',
        });

        return result;
      },
      onSuccess: (result) => {
        if (changeNo === 0) {
          setSlug(result.slug);
        }
      },
      onError: (err) => {
        if (
          isApiError(err)
          && err.response
          && err.response.data.errors.slug
        ) {
          setError(err.response.data.errors.slug);
        } else {
          const message = apiErrorService
            .getMessage(err);

          toastService.error(message);
        }
      },
      cacheTime: 15000,
      staleTime: 10000,
      refetchOnMount: 'always',
    });

  const isUnique = !isFetching && isSuccess && data.isUnique;
  const isNonUnique = !isFetching && isSuccess && !data.isUnique;

  const isCurrentQuery = isSuccess && data.slug === slug;
  const isAppropriate = !error && isUnique && isCurrentQuery;

  useEffect(() => {
    if (isNonUnique && isCurrentQuery && !error) {
      setTimeout(() => (
        setError('За такою адресою вже опубліковано людину')
      ), 0);
    }
  }, [isNonUnique, slug, error]);

  return (
    <>
      <p className="fs-5 mb-1">
        Сторінка стане доступна за посиланням:
      </p>

      <div className="d-flex align-items-center fs-5 gap-2">
        <span>{`${process.env.REACT_APP_FILES_URL}/`}</span>

        <div className="position-relative">
          <Field
            name={name}
            readOnly={isFetching}
            className={cn(
              'form-control border-secondary disabled ps-2 fs-5',
              {
                'border-success': isAppropriate,
                'border-danger': error,
              },
            )}
            autoComplete="off"
          />
          {isFetching && (
            <LoadingOverlay className="bg-light" />
          )}

          <i
            className={cn(
              'bi me-3 fs-4 lh-1',
              'position-absolute top-50 end-0 translate-middle-y',
              {
                'text-success bi-check-circle': isAppropriate,
                'text-danger bi-exclamation-triangle': error,
              },
            )}
          />
        </div>
      </div>

      <p className="mt-2 text-danger lh-sm" style={{ fontSize: 14 }}>
        {touched && error}
      </p>
    </>
  );
};
