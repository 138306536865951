import * as Yup from 'yup';
import { idSchema } from './idSchema.js';
import { slugSchema } from './slugSchema.js';
import { victimStatusSchema } from './victimStatusSchema.js';
export const statusChangerFormSchema = Yup
    .object()
    .shape({
    slug: slugSchema,
    status: victimStatusSchema,
});
export const preferredSlugDtoSchema = Yup
    .object()
    .shape({
    slug: slugSchema
        .when('victimId', {
        is: undefined,
        then: (schema) => schema.required("Слаг обов'язковий"),
    }),
    victimId: idSchema
        .optional(),
});
