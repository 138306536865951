export const PLAQUE_VALIDATION_MSG = {
    ua: {
        victim: {
            required: 'Оберіть людину',
        },
        victimId: {
            required: "Id людини обов'язковий",
        },
        photo: {
            required: "Фотографія людини обов'язкова",
        },
        callSign: {
            max: 'Позивний може містити максимум {{maxLength}} символів',
        },
        unit: {
            max: 'Назва підрозділу може містити максимум {{maxLength}} символів',
        },
        address: {
            max: 'Адреса може містити максимум {{maxLength}} символів',
            required: "Адреса обов'язкова",
        },
        email: {
            required: "Електронна пошта обов'язкова",
            wrongFormat: 'Некоректна електронна пошта',
        },
        phone: {
            wrongFormat: 'Некоректний номер телефону',
        },
    },
    en: {
        victim: {
            required: 'Select a person',
        },
        victimId: {
            required: "Person's id is required",
        },
        photo: {
            required: 'Photo of the person is required',
        },
        callSign: {
            max: 'Call sign can contain maximum of {{maxLength}} characters',
        },
        unit: {
            max: 'Unit name can contain maximum of {{maxLength}} characters',
        },
        address: {
            max: 'An address can contain a maximum of {{maxLength}} characters',
            required: 'Address is required',
        },
        email: {
            required: 'Email is required',
            wrongFormat: 'Invalid email format',
        },
        phone: {
            wrongFormat: 'Invalid phone number format',
        },
    },
};
