import React, { ComponentProps } from 'react';
import cn from 'classnames';
import { Field } from 'formik';
import { withErrorMessage } from '../withErrorMessage';

type Props = {
  name: string;
  className?: string;
  children?: React.ReactNode;
} & ComponentProps<typeof Field>;

export const TextField: React.FC<Props> = withErrorMessage<Props>(({
  name,
  className,
  children,
  ...props
}) => {
  return (
    <div className="position-relative">
      <Field
        name={name}
        className={cn('form-control rounded-pill fs-4', className)}
        autoComplete="off"
        {...props}
      />

      {children}
    </div>
  );
});
