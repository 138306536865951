import { omitBy, isUndefined } from 'lodash';
import queryString from 'query-string';
import {
  IPlaque,
  IVictimFull,
  Locale,
  UniqueSlugPayload,
  UniqueSlugResult,
} from 'shared';
import { adminClient } from '../client/adminClient';
import { IArticle } from '../types/article';
import { Icon } from '../types/Icon';
import {
  ChangeVictimStatusPayload,
  GetAdminVictimsOutputPayload,
  UpdateVictimPayload,
} from '../types/api/victim';
import {
  CreateArticlePayload,
  UpdateArticlePayload,
} from '../types/api/article';
import {
  ChangePlaqueStatusPayload,
  UpdatePlaquePayload,
} from '../types/api/plaque';

async function getVictims(): Promise<GetAdminVictimsOutputPayload> {
  return adminClient.get('/victims');
}

async function getIcons(): Promise<Icon[]> {
  return adminClient.get('/icons');
}

async function changeVictimStatus(
  { id, status, slug }: ChangeVictimStatusPayload,
): Promise<void> {
  return adminClient.patch(
    `/victims/${id}/status`,
    {
      status,
      slug: status === 'approved' ? slug : undefined,
    },
  );
}

async function updateVictim(
  id: number,
  payload: UpdateVictimPayload,
  locale: Locale,
): Promise<IVictimFull> {
  const { noDeathDate, fatherName, ...victimProps } = payload;

  return adminClient.patch(
    `/victims/${id}?locale=${locale}`,
    {
      ...victimProps,
      fatherName: fatherName === '' ? null : fatherName,
    },
  );
}

async function createArticle(
  payload: CreateArticlePayload,
): Promise<IArticle> {
  const { icon, ...articleProps } = payload;

  return adminClient.post('/articles', {
    ...articleProps,
    iconId: icon?.value,
  });
}

async function updateArticle(
  id: number,
  payload: UpdateArticlePayload,
): Promise<IArticle> {
  const { icon, ...articleProps } = payload;

  return adminClient.patch(`/articles/${id}`, {
    ...articleProps,
    iconId: icon?.value,
  });
}

async function deleteArticle(id: number) {
  return adminClient.delete(`/articles/${id}`);
}

async function getPlaques(): Promise<IPlaque[]> {
  return adminClient.get('/plaques');
}

async function changePlaqueStatus(
  { id, status }: ChangePlaqueStatusPayload,
): Promise<void> {
  return adminClient.patch(`/plaques/${id}/status`, { status });
}

async function updatePlaque(
  id: number,
  payload: UpdatePlaquePayload,
  locale: Locale,
): Promise<IPlaque> {
  const { victim, ...data } = payload;

  return adminClient.patch(
    `/plaques/${id}?locale=${locale}`,
    {
      ...omitBy(data, isUndefined),
      victimId: victim?.value,
    },
  );
}

export function getPreferredSlug(
  { victimId, slug, locale }: UniqueSlugPayload,
): Promise<UniqueSlugResult> {
  const queryParams = queryString.stringify(
    { victimId: slug ? undefined : victimId, slug, locale },
    { skipEmptyString: true },
  );

  return adminClient.get(`/victims/preferred-slug?${queryParams}`);
}

export const adminService = {
  getVictims,
  getIcons,
  changeVictimStatus,
  updateVictim,
  createArticle,
  updateArticle,
  deleteArticle,
  getPlaques,
  changePlaqueStatus,
  updatePlaque,
  getPreferredSlug,
};
