export function convertLocale(
  locale: string | undefined,
) {
  switch (locale) {
    case 'ua':
      return 'uk-UA';
    case 'en':
      return 'en-US';
    default:
      return 'uk-UA';
  }
}
