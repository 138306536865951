/* eslint-disable max-len */
import React, { useEffect, useState } from 'react';
import cn from 'classnames';
import { NavLink, useLocation, useSearchParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import './PublicNav.css';

import { searchParamsNames } from '../../constants/search';
import { getSearchWith } from '../../helpers/searchHelper';
import { useDidUpdateEffect } from '../../hooks/useDidUpdateEffect';

const buttons = [
  { labelKey: 'heroes', icon: 'bi bi-shield', path: '/' },
  { labelKey: 'events', icon: 'bi bi-calendar4-week', path: '/timeline' },
  { labelKey: 'civilianCasualties', icon: 'bi bi-people', path: '/civilians' },
];

const placeholder: { [key: string]: string } = {
  '/': 'searchByNamePlaceholder',
  '/timeline': 'searchByEventPlaceholder',
  '/civilians': 'searchByNamePlaceholder',
};

export const PublicNav: React.FC = React.memo(() => {
  const location = useLocation();
  const [searchParams, setSearchParams] = useSearchParams();
  const [query, setQuery] = useState('');
  const { t } = useTranslation('main');

  useEffect(() => {
    setQuery(searchParams.get(searchParamsNames.query) ?? '');
  }, []);

  useDidUpdateEffect(() => {
    setSearchParams(
      getSearchWith(
        searchParams,
        { [searchParamsNames.query]: query || null },
      ),
    );
  }, [query]);

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setQuery(event.target.value);
  };

  return (
    <>
      <div className="row flex-column flex-md-row justify-content-center mb-3 mb-md-4 mb-lg-5 gx-3 gx-lg-4">
        {buttons.map((button) => (
          <div key={button.labelKey} className="col-12 col-md-4 mb-3 mb-md-0">
            <NavLink
              to={button.path}
              className={({ isActive }) => (
                cn(
                  'btn btn-secondary btn-lg rounded-pill border-white',
                  'border-2 fw-medium w-100 fs-4 p-2 bg-grey',
                  {
                    'bg-red': isActive,
                  },
                )
              )}
            >
              {`${t(button.labelKey)} `}
              <i className={button.icon} />
            </NavLink>
          </div>
        ))}
      </div>

      <div className="input-group mb-5 d-flex flex-nowrap align-items-center
        bg-white rounded-pill ps-4 fs-4 overflow-hidden"
      >
        <i className="bi bi-search" />
        <input
          type="text"
          className="flex-grow-1 p-2 ps-md-4 fs-4"
          placeholder={t(placeholder[location.pathname])}
          value={query}
          onChange={handleSearchChange}
        />
      </div>
    </>
  );
});
