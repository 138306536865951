import React from 'react';
import ReactQuill, { ReactQuillProps } from 'react-quill';
import { useField } from 'formik';

import { applicationQuillConfig } from './config';

import 'react-quill/dist/quill.snow.css';
import './ApplicationQuill.css';
import { withErrorMessage } from '../withErrorMessage';

type Props = {
  name: string;
} & ReactQuillProps;

export const ApplicationQuill: React.FC<Props> = withErrorMessage(({
  name,
  ...props
}) => {
  const [, meta, helpers] = useField<string>(name);

  const { value } = meta;
  const { setValue } = helpers;

  const onChange = (v: string) => {
    setValue(v === '<p><br></p>' ? '' : v);
  };

  return (
    <ReactQuill
      theme="snow"
      value={value}
      onChange={onChange}
      modules={applicationQuillConfig.modules}
      formats={applicationQuillConfig.formats}
      {...props}
    />
  );
});
