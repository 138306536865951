/* eslint-disable max-len */
import React from 'react';
import { v4 as uuid } from 'uuid';
import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';

import { adminService } from '../../services/adminService';
import { VictimsTable } from '../../components/VictimsTable';
import { useAppDispatch, useAppSelector } from '../../redux/hook';
import { setVictims } from '../../redux/features/victimsSlice';

import { AdminPanelLayout } from '../../components/AdminPanelLayout';
import { StatusChanger } from '../../components/StatusChanger';
import { VictimForm } from '../../components/VictimForm';

export const VictimPage: React.FC = () => {
  const dispatch = useAppDispatch();
  const { victims } = useAppSelector(state => state.victims);
  const { isSuccess, isLoading, isError } = useQuery({
    queryKey: ['victims'],
    queryFn: adminService.getVictims,
    onSuccess: (victimsFromServer) => {
      dispatch(setVictims(victimsFromServer));
    },
  });
  const { id: idParam } = useParams();
  const id = idParam ? parseInt(idParam, 10) : null;

  const selectedVictim = victims.find(app => app.id === id);

  return (
    <AdminPanelLayout
      leftPanel={(
        <>
          {isLoading && (
            <div className="spinner-border d-block m-auto" role="status">
              <span className="visually-hidden">Loading...</span>
            </div>
          )}

          {isError && (
            <div className="alert alert-danger" role="alert">
              Помилка під час завантаження заявок
            </div>
          )}

          {isSuccess && (
            <VictimsTable selectedId={id} />
          )}
        </>
      )}
      rightPanel={(
        <>
          {selectedVictim ? (
            <>
              <div className="container-fluid p-0 mb-5">
                <StatusChanger
                  key={uuid()}
                  id={selectedVictim.id}
                  initialStatus={selectedVictim.status}
                  changeFor="victim"
                />
              </div>

              <VictimForm locale="ua" currentVictim={selectedVictim} />
            </>
          ) : (
            <p className="m-0 fs-4">
              Виберіть людину зі списку&nbsp;
              <i className="bi bi-exclamation-triangle text-primary" />
            </p>
          )}
        </>
      )}
    />
  );
};
