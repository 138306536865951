/* eslint-disable react/jsx-one-expression-per-line */
import React, { useEffect } from 'react';
import { useMutation } from 'react-query';
import { Route, Routes, Navigate } from 'react-router-dom';

import { MainLayout } from './components/MainLayout';
import { VictimPage } from './pages/VictimsPage';
import { LoginPage } from './pages/LoginPage/LoginPage';
import { RequireAuth } from './components/RequireAuth';
import { accessTokenService } from './services/accessTokenService';
import { authService } from './services/authService';
import { useAppDispatch, useAppSelector } from './redux/hook';
import { check } from './redux/features/authSlice';
import { ApplicationPage } from './pages/ApplicationPage';
import { PublicMainLayout } from './components/PublicMainLayout';
import { VictimInfoPage } from './pages/VictimInfoPage';
import { TimelinePage } from './pages/TimelinePage';
import { useBackgroundImage } from './hooks/useBackgroundImage';
import { ArticlesAdminPage } from './pages/ArticlesAdminPage';
import { HeroesPage } from './pages/HeroesPage';
import { CiviliansPage } from './pages/CiviliansPage';
import { PlaquePage } from './pages/PlaquePage';
import { PlaquesAdminPage } from './pages/PlaquesAdminPage/PlaquesAdminPage';

export const App: React.FC = () => {
  const { isChecked } = useAppSelector(state => state.auth);
  const dispatch = useAppDispatch();

  const checkMutation = useMutation({
    mutationFn: async () => {
      const accessToken = accessTokenService.get();
      const userData = await authService.refresh({ accessToken });

      dispatch(check(userData));
    },
    onError: () => {
      dispatch(check());
    },
  });

  useEffect(() => {
    if (process.env.NODE_ENV === 'production') {
      if (window.location.protocol === 'http:') {
        window.location.href = `https://${window.location.host}${window.location.pathname}`;
      }
    }
  }, []);

  useEffect(() => {
    checkMutation.mutate();
  }, []);

  useBackgroundImage();

  return (
    isChecked && (
      <Routes>
        <Route path="/" element={<PublicMainLayout />}>
          <Route index element={<HeroesPage />} />
          <Route path="home" element={<Navigate to="/" replace />} />
          <Route path="heroes" element={<Navigate to="/" replace />} />
          <Route path="civilians" element={<CiviliansPage />} />
          <Route path="timeline" element={<TimelinePage />} />
          <Route path="application" element={<ApplicationPage />} />
          <Route path="memory-plaque" element={<PlaquePage />} />

          <Route path=":slug" element={<VictimInfoPage />} />
        </Route>

        <Route
          path="admin"
          element={<RequireAuth><MainLayout /></RequireAuth>}
        >
          <Route index element={<Navigate to="/admin/victims" replace />} />
          <Route path="victims" element={<VictimPage />} />
          <Route path="victims/:id" element={<VictimPage />} />

          <Route path="articles" element={<ArticlesAdminPage />} />

          <Route path="plaques" element={<PlaquesAdminPage />} />
          <Route path="plaques/:id" element={<PlaquesAdminPage />} />
        </Route>

        <Route path="/login" element={<LoginPage />} />

        <Route path="*" element={<p>Page Not Found</p>} />
      </Routes>
    )
  );
};
