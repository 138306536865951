import React from 'react';
import DatePicker from 'react-date-picker';
import { useField } from 'formik';

import i18n from '../../i18n';
import { withErrorMessage } from '../withErrorMessage';
import { convertLocale } from '../../helpers/convertLocale';

import 'react-date-picker/dist/DatePicker.css';
import 'react-calendar/dist/Calendar.css';
import './ApplicationDatePicker.css';

type Props = {
  name: string;
  disabled?: boolean;
};

export const ApplicationDatePicker: React.FC<Props> = withErrorMessage(({
  name,
  disabled = false,
}) => {
  const [, meta, helpers] = useField(name);

  const { value } = meta;
  const { setValue } = helpers;
  const locale = convertLocale(i18n.resolvedLanguage);

  return (
    <DatePicker
      value={value}
      locale={locale}
      minDate={new Date(1900, 1, 1)}
      maxDate={new Date(2040, 1, 1)}
      clearIcon={null}
      calendarIcon={<i className="bi bi-calendar" />}
      calendarClassName="calendar--custom"
      className="form-control rounded-pill fs-4 border-2 py-1"
      onChange={setValue}
      openCalendarOnFocus={false}
      disabled={disabled}
    />
  );
});
