/* eslint-disable no-param-reassign */
import { IPlaque } from 'shared';
import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { ChangeVictimStatusPayload } from '../../types/api/victim';

interface StateType {
  plaques: IPlaque[];
}

type ChangeStatusPayload = PayloadAction<ChangeVictimStatusPayload>;

const initialState: StateType = {
  plaques: [],
};

const plaquesSlice = createSlice({
  name: 'plaquesSlice',
  initialState,
  reducers: {
    setPlaques: (state, action: PayloadAction<IPlaque[]>) => {
      state.plaques = action.payload;
    },
    changePlaqueStatus: (state, action: ChangeStatusPayload) => {
      state.plaques = state.plaques.map(plaque => {
        if (plaque.id !== action.payload.id) {
          return plaque;
        }

        return {
          ...plaque,
          status: action.payload.status,
        };
      });
    },
    addPlaque: (state, action: PayloadAction<IPlaque>) => {
      state.plaques = [action.payload, ...state.plaques];
    },
    updatePlaque: (state, action: PayloadAction<IPlaque>) => {
      state.plaques = state.plaques.map(plaque => (
        plaque.id === action.payload.id
          ? action.payload
          : plaque
      ));
    },
  },
});

export const {
  setPlaques,
  changePlaqueStatus,
  addPlaque,
  updatePlaque,
} = plaquesSlice.actions;

export default plaquesSlice.reducer;
