import { useState } from 'react';

type UsePage = (startPage?: number) => ({
  page: number;
  increasePage: () => void;
  reset: () => void;
});

export const usePage: UsePage = (startPage = 0) => {
  const [page, setPage] = useState(startPage);

  const increasePage = () => setPage(prevPage => prevPage + 1);

  const reset = () => setPage(startPage);

  return {
    page,
    increasePage,
    reset,
  };
};
