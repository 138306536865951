import React from 'react';
import cn from 'classnames';
import { Link, useSearchParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useDebounce } from '@uidotdev/usehooks';

import { VictimsList } from '../VictimsList';
import { PublicNav } from '../PublicNav';
import { searchParamsNames } from '../../constants/search';
import { VICTIMS_SEARCH_DEBOUNCE_MS } from '../../constants/main';

const buttons = [
  { labelKey: 'suggest', icon: 'bi bi-person-plus', path: '/application' },
  {
    labelKey: 'memoryPlaque',
    icon: 'bi bi-person-vcard',
    path: '/memory-plaque',
  },
];

type Props = {
  isMilitary: boolean;
};

export const VictimsPageProvider: React.FC<Props> = ({ isMilitary }) => {
  const [searchParams] = useSearchParams();
  const { t } = useTranslation('main');
  const debouncedQuery = useDebounce(
    searchParams.get(searchParamsNames.query) ?? '',
    VICTIMS_SEARCH_DEBOUNCE_MS,
  );

  return (
    <div className="container">
      <PublicNav />

      <div className="row justify-content-center gx-4 mb-4">
        {buttons.map(({ labelKey, icon, path }, i) => (
          <div
            className={cn(
              'col-8 col-sm-6 col-md-4',
              {
                'mb-3': i !== buttons.length - 1,
              },
            )}
            key={labelKey}
          >
            <Link
              to={path}
              className="btn btn-secondary bg-grey btn-lg rounded-pill
                  border-white border-2 fw-medium w-100 fs-4 p-2 shadow-extra"
            >
              <span>
                {t(labelKey)}
                {' '}
              </span>
              <i className={icon} />
            </Link>
          </div>
        ))}
      </div>

      <VictimsList
        query={debouncedQuery}
        isMilitary={isMilitary}
      />
    </div>
  );
};
