import { ErrorMessage } from 'formik';
import { FC } from 'react';

export const withErrorMessage = <P extends { name: string }>(
  WrappedComponent: FC<P>,
): FC<P> => {
  return ({ ...props }) => (
    <>
      <WrappedComponent {...props} />

      <span className="form-text ms-2 mt-0 text-danger">
        <ErrorMessage name={props.name} />
      </span>
    </>
  );
};
