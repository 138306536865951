import * as Yup from 'yup';
import { iconColors } from '../../index.js';
import { checkIfStringIsDate } from '../utils/checkIfStringIsDate.js';
// form validation
const titleSchema = Yup
    .string()
    .required('Заголовок обов\'язковий')
    .max(250, 'Заголовок повинен містити не більше 250 символів');
const titleEmptySchema = Yup
    .string()
    .defined()
    .nonNullable()
    .max(250, 'Заголовок повинен містити не більше 250 символів');
const colorSchema = Yup
    .string()
    .required('Колір обов\'язковий')
    .oneOf(Object.values(iconColors), `Колір повинен бути одним із ${Object.values(iconColors)}`);
const iconSchema = Yup
    .object()
    .required('Іконка події обов\'язкова')
    .shape({
    value: Yup.number().nonNullable(),
    label: Yup.string().nonNullable(),
});
const dateSchema = Yup
    .date()
    .required('Дата обов\'язкова');
const textSchema = Yup
    .string()
    .required('Опис події обов\'язковий')
    .max(65000, 'Опис події повинен містити не більше 65000 символів');
const textEmptySchema = Yup
    .string()
    .defined()
    .nonNullable()
    .max(65000, 'Опис події повинен містити не більше 65000 символів');
const imageSchema = Yup
    .string()
    .nullable();
// dto validation
const iconIdSchema = Yup
    .number()
    .required('Id іконки обов\'язковий');
const dateAsStringSchema = Yup
    .string()
    .required('Дата події обов\'язкова')
    .test('is-date', 'Рядок неможливо перевести у дату', checkIfStringIsDate);
const contentFormSchema = Yup.object().shape({
    ua: Yup
        .object()
        .shape({
        text: textSchema,
        title: titleSchema,
    }),
    en: Yup
        .object()
        .shape({
        text: textSchema.optional(),
        title: titleSchema.optional(),
    }),
});
const contentDtoSchema = Yup.object().shape({
    ua: Yup
        .object()
        .shape({
        text: textSchema,
        title: titleSchema,
    }),
    en: Yup
        .object()
        .shape({
        text: textEmptySchema,
        title: titleEmptySchema,
    }),
});
export const articleFormSchema = Yup
    .object()
    .shape({
    color: colorSchema,
    icon: iconSchema,
    date: dateSchema,
    image: imageSchema,
    content: contentFormSchema,
});
export const articleDtoSchema = Yup
    .object()
    .shape({
    color: colorSchema,
    iconId: iconIdSchema,
    date: dateAsStringSchema,
    image: imageSchema,
    content: contentDtoSchema,
})
    .noUnknown((params) => `Наступні передані параметри зайві: ${params.unknown}`)
    .strict();
export const articleOptionalDtoSchema = Yup
    .object()
    .shape({
    color: colorSchema.optional(),
    iconId: iconIdSchema.optional(),
    date: dateAsStringSchema.optional(),
    image: imageSchema.optional(),
    content: Yup
        .object()
        .shape({
        ua: Yup
            .object()
            .shape({
            text: textSchema.optional(),
            title: titleSchema.optional(),
        })
            .optional(),
        en: Yup
            .object()
            .shape({
            text: textEmptySchema.optional(),
            title: titleEmptySchema.optional(),
        })
            .optional(),
    })
        .optional(),
})
    .noUnknown((params) => `Наступні передані параметри зайві: ${params.unknown}`)
    .strict();
